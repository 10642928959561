import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import FadeIn from '../fade-in'

const Boarding = () => (
    <div id="boarding" className="options">
        <div id="twoWeek" className="option">
            <FadeIn>
                <h3>Two Week E-Collar Board and Train - $995/week + e-collar</h3>
            </FadeIn>
            <hr />
            <div className="flex">
                <FadeIn>
                    <h4>What your dog will learn:</h4>
                    <ul>
                        <li>E-collar basics</li>
                        <li>
                            Leash manners - including heel with an automatic sit
                        </li>
                        <li>Sit stay</li>
                        <li>Down stay</li>
                        <li>Place</li>
                        <li>Basic recall (&ldquo;come&rdquo; command)</li>
                        <li>Beginning of off leash heel</li>
                        <li>Social skills with humans and other dogs</li>
                    </ul>
                    <h4>Who is this for:</h4>
                    <p>
                        This program is designed for people to master full
                        control of their dogs, in and out of the home, with the
                        freedom of no leash. Whether you are hiking, at the
                        beach, camping, or walking the streets of town, the
                        e-collar will give you off-leash reliability to recall,
                        sit, down, heel, and generally enjoy your dog without
                        fear for their safety or for the safety of those around
                        them.
                    </p>
                    <p>
                        <b>PLEASE NOTE</b> this program is not suitable for dogs
                        with aggression and/or fear issues. We do help dogs with
                        these issues but under a different program: please see{' '}
                        <b>
                            <i>
                                <AnchorLink href="#manyWeek">
                                    Behavioural Board and Train
                                </AnchorLink>
                            </i>
                        </b>{' '}
                        below.
                    </p>
                </FadeIn>
                <FadeIn>
                    <h4>What is included:</h4>
                    <p>
                        Your dog will enjoy one-on-one training and group
                        sessions with other dogs to learn good behaviour and
                        self control in distracting environments.
                    </p>
                    <p>
                        We will transfer all obedience commands onto the
                        e-collar step by step, and include you in the process
                        every Friday with a lesson and training for you to take
                        home and practice on the weekend. This is what will give
                        you full control and off leash reliability.
                    </p>
                    <p>
                        We have designed this program to bridge the gap between
                        dogs being obedient for their trainer and having the
                        same success at home with their owner. This is an
                        important step, for you to gain the same control,
                        confidence and working relationship as we can, step by
                        step.
                    </p>
                    <p>
                        These training sessions will create new ways to
                        communicate with your dog so you can both enjoy a happy
                        balanced life at home and in public.
                    </p>
                    <p>
                        During a <i>Two Week E-Collar Board and Train</i> your
                        dog will be training multiple sessions every day. There
                        will also be plenty of play and social time with our
                        balanced-dog-pack on our six acres of property. Play
                        time is an important part of a dog's training as it will
                        be supervised by professional trainers who will be
                        teaching your dog and helping them to make good choices
                        and habits.
                    </p>
                </FadeIn>
            </div>
        </div>
        <div id="manyWeek" className="option">
            <FadeIn>
                <h3>
                    Four Week Behavioural Board and Train - $1015/week +
                    e-collar
                </h3>
            </FadeIn>
            <hr />
            <div className="flex">
                <FadeIn>
                    <h4>
                        As well as rehabilitation work, your dog will learn:
                    </h4>
                    <ul>
                        <li>E-collar basics</li>
                        <li>
                            Leash manners - including heel with an automatic sit
                        </li>
                        <li>Sit stay</li>
                        <li>Down stay</li>
                        <li>Place</li>
                        <li>Basic recall (&ldquo;come&rdquo; command)</li>
                        <li>Beginning of off leash heel</li>
                        <li>Social skills with humans and dogs</li>
                    </ul>
                </FadeIn>
                <FadeIn>
                    <h4>Who is this for:</h4>
                    <p>
                        This program is designed for people who love their dogs
                        but are frustrated, scared, embarrassed, and/or
                        generally concerned for their dog and their behaviour at
                        home and/or in public.
                    </p>
                    <p>
                        Behavioural problems can include, but are not limited
                        to:
                    </p>
                    <ul>
                        <li>Fear</li>
                        <li>Anxiety</li>
                        <li>Resource guarding</li>
                        <li>Reactivity</li>
                        <li>Social problems</li>
                        <li>Dog aggression</li>
                        <li>Human aggression</li>
                        <li>Separation anxiety</li>
                    </ul>
                </FadeIn>
            </div>
            <h4>What is included:</h4>
            <div className="flex included">
                <FadeIn>
                    <ul>
                        <li>
                            <b>
                                An intensive customized training program for
                                your dog
                            </b>{' '}
                            - Once we get to know your dog, and where their
                            issues stem from, we are able to create a specific
                            training program that works for them. We understand
                            that every dog is different, just like every person,
                            and there is no one-size-fits-all training for
                            behavioural problems. We will transfer all obedience
                            commands onto the e-collar step by step. You will be
                            included in the process every Friday with a training
                            session. There will be specific homework to take
                            home on the weekend, designed to fit your needs. The
                            weekends are tailored and specific to start changing
                            the negative dynamics between you and your dog.
                        </li>
                        <li>
                            The length of your dog's stay will be determined by
                            the severity of your dog's behaviour. Each dog will
                            have multiple training sessions each day with
                            certified trainers. Once your dog has reached a
                            measurable success point in their training we will
                            take them into town to solidify their learning
                            before they go home.
                        </li>
                    </ul>
                </FadeIn>
                <FadeIn>
                    <ul>
                        <li>
                            <b>Tailored lessons for the humans</b> - there will
                            be multiple training sessions for you over the
                            course of your dog's stay. After we work with your
                            dog to change unwanted behaviours, we will begin
                            gaining an understanding of their inner workings. It
                            is essential that this knowledge be passed on to you
                            to ensure your dog doesn’t fall back into old
                            behaviours. During these sessions we will focus on
                            what your dog has learned and teach you the
                            necessary skills to continue their success and
                            communicate effectively with your canine partner.
                            Through the process we will also build your
                            confidence in handling and living with your dog
                            confidently.
                        </li>
                        <li>
                            The weekends are tailored and specific to your
                            situation to start changing the negative dynamics
                            between you and your dog.
                        </li>
                    </ul>
                </FadeIn>
            </div>
            <FadeIn>
                <p>
                    We have designed this program to bridge the gap between dogs
                    being obedient for their trainer and having the same success
                    at home with their owner. This is an important step, for you
                    to gain the same control, confidence and working
                    relationship as we can, step by step.
                </p>
                <p>
                    <b>Ongoing support</b> – it is important that you feel
                    supported in your renewed relationship with your dog. You
                    will have unlimited support over the phone as well as up to
                    three lessons at Polite Paws during the three months
                    following your dog's time with us. This three months of
                    support is intended as continued assistance with the
                    behavioural issues that were addressed during the board and
                    train program.
                </p>
            </FadeIn>
            <div id="ourPack">
                <FadeIn>
                    <h4>Our Pack:</h4>
                    <p>
                        Our dogs have been helping humans and their canine
                        friends for years. Our own pack of balanced dogs work
                        with us every day and have helped hundreds of family
                        dogs as a critical part in teaching. Helping dogs with
                        socialization, resource guarding, fear, dog aggression
                        and anxiety are all part of our packs job. Training with
                        a pack of balanced dogs is imperative part of the
                        success in training your dog.
                    </p>
                </FadeIn>
            </div>
        </div>
    </div>
)

export default Boarding
