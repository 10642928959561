import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import FadeIn from '../fade-in'

const ServicesOverview = () => (
    <FadeIn>
        <div className="servicesOverview">
            <h2>Polite Paws Packages</h2>
            <div>
                <h3>Obedience Training</h3>
                <AnchorLink href="#obedience">
                    Obedience Classes
                </AnchorLink> |{' '}
                <AnchorLink href="#oneOnOne">One-on-one Training</AnchorLink>
            </div>
            <div>
                <h3>Board and Train</h3>
                <AnchorLink href="#twoWeek">
                    Two Week E-Collar Board and Train
                </AnchorLink>{' '}
                |{' '}
                <AnchorLink href="#manyWeek">
                    Four Week Behavioural Board and Train
                </AnchorLink>
            </div>
        </div>
    </FadeIn>
)

export default ServicesOverview
