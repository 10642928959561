import fetch from 'isomorphic-fetch'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import React, { useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

const validate = values => {
    const errors = {}

    if (!values.name) errors.name = 'Required'
    if (!values.message) errors.message = 'Required'

    if (!values.email) {
        errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }

    if (!values.phone) {
        errors.phone = 'Required'
    } else {
        const numDigits = values.phone.match(/\d/g).length

        if (numDigits < 10 || numDigits > 11) {
            errors.email = 'Invalid phone number'
        }
    }

    return errors
}

const ContactForm = () => {
    const [response, setResponse] = useState(undefined)
    const recaptchaRef = useRef(null)
    const [captchaPassed, setCaptchaPassed] = useState(false)

    const onCaptchaChange = () => {
        setCaptchaPassed(true)
    }

    const onSubmit = async (values, actions) => {
        let res

        try {
            res = await fetch('/api/contact', {
                body: JSON.stringify(values),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                processData: false,
            })
        } catch (e) {
            console.log(e)
        }

        if (res.status < 400) {
            setResponse('Thank You! Your message has been sent.')
        } else {
            setResponse(
                typeof res.error === 'string'
                    ? res.error
                    : 'Uh oh! Something went wrong, please try again.'
            )
        }

        actions.resetForm()
        recaptchaRef.current.reset()
    }

    return (
        <Formik
            initialValues={{ name: '', email: '', phone: '', message: '' }}
            validate={validate}
            onSubmit={onSubmit}
        >
            {({ handleSubmit, isSubmitting }) => (
                <Form className="contactForm" onSubmit={handleSubmit}>
                    <div className="field">
                        <label htmlFor="name">Name</label>
                        <Field name="name" />
                        <ErrorMessage name="name" component="div" />
                    </div>

                    <div className="field">
                        <label htmlFor="email">Email</label>
                        <Field type="email" name="email" />
                        <ErrorMessage name="email" component="div" />
                    </div>

                    <div className="field">
                        <label htmlFor="phone">Phone Number</label>
                        <Field type="tel" name="phone" />
                        <ErrorMessage name="phone" component="div" />
                    </div>

                    <div className="field message">
                        <label htmlFor="message">Message</label>
                        <Field component="textarea" name="message" rows="5" />
                        <ErrorMessage name="message" component="div" />
                    </div>

                    <ReCAPTCHA
                        sitekey="6LegaR8lAAAAAJaGDFqyBSQB8WfJZN7YKo2GQhJn"
                        onChange={onCaptchaChange}
                        ref={recaptchaRef}
                    />

                    <button
                        type="submit"
                        disabled={!captchaPassed || isSubmitting}
                        className={
                            !captchaPassed || isSubmitting ? 'disabled' : ''
                        }
                    >
                        {isSubmitting ? 'Submitting...' : 'Submit'}
                    </button>

                    {response && <div className="response">{response}</div>}
                </Form>
            )}
        </Formik>
    )
}

export default ContactForm
