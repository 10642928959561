import React from 'react'
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    PinterestIcon,
    PinterestShareButton,
    TwitterIcon,
    TwitterShareButton,
} from 'react-share'

import FbIcon from '../../images/icons/facebook.svg'
import LocationIcon from '../../images/icons/location.svg'
import PhoneIcon from '../../images/icons/phone.svg'

import ContactForm from './contact-form'

const shareUrl = 'http://politepawsk9school.com'

const Contact = () => (
    <div id="contact">
        <h2>Get in Touch</h2>
        <p>
            We would love to hear all your questions, inquiries, and comments!
        </p>
        <div className="flex">
            <div>
                <ContactForm />
            </div>
            <div className="contactInfo">
                <h3>Contact Info</h3>
                <hr />
                <ul className="contactInfo__links">
                    <li>
                        <a href="tel:2503170553">
                            <PhoneIcon />
                            (250) 317-0553
                        </a>
                    </li>
                    <li>
                        <a
                            href="mailto:lp@politepawsk9school.com"
                            className="emailLink"
                        >
                            <EmailIcon />
                            lp@politepawsk9school.com
                        </a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/pages/Polite-Paws/584573791671522?fref=pb&hc_location=profile_browser">
                            <FbIcon />
                            Join us on Facebook!
                        </a>
                    </li>
                    <li>
                        <a href="https://www.google.ca/maps/place/Polite+Paws/@49.863172,-119.334333,15z/data=!4m2!3m1!1s0x0:0x1ff1132673c6e50d">
                            <LocationIcon />
                            Come visit!
                        </a>
                    </li>
                </ul>
                <p>
                    <strong>
                        Polite Paws Training and Rehabilitation Centre
                    </strong>
                    <br />
                    Located in Kelowna, B.C.
                </p>
                <div className="shareButtons">
                    <FacebookShareButton url={shareUrl}>
                        <FacebookIcon size={30} round />
                    </FacebookShareButton>
                    <TwitterShareButton url={shareUrl}>
                        <TwitterIcon size={30} round />
                    </TwitterShareButton>
                    <PinterestShareButton url={shareUrl}>
                        <PinterestIcon size={30} round />
                    </PinterestShareButton>
                    <EmailShareButton>
                        <EmailIcon size={30} round />
                    </EmailShareButton>
                </div>
            </div>
        </div>
    </div>
)

export default Contact
