import React from 'react'

import FadeIn from './fade-in'

const Mission = () => (
    <div className="section mission pad">
        <FadeIn>
            <blockquote>
                <p>
                    My mission is to build better relationships and better
                    understanding between humans and dogs. Located on six acres
                    in the Black Mountain area, I offer a variety of services
                    for you and your dog.
                </p>
                <footer>- Laura Puffalt</footer>
            </blockquote>
        </FadeIn>
    </div>
)

export default Mission
