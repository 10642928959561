import React, { useEffect, useState, useRef } from 'react'

function FadeIn(props) {
    const [isVisible, setVisible] = useState(true)
    const domRef = useRef()

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting))
        })

        observer.observe(domRef.current)

        const el = domRef.current

        return () => observer.unobserve(el)
    }, [])

    return (
        <div
            className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
            ref={domRef}
        >
            {props.children}
        </div>
    )
}

export default FadeIn
