import React from 'react'

import Paw from './paw'
import Paws from './paws'

const Hero = () => (
    <div id="hero">
        <Paws />
        <div className="welcome">
            <Paw />
            <h1>Welcome to Polite Paws Training and Rehabilitation Centre</h1>
        </div>
    </div>
)

export default Hero
