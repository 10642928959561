import React from 'react'

import FadeIn from './fade-in'

const Testimonials = () => (
    <div id="testimonials">
        <FadeIn>
            <h2>Testimonials</h2>
        </FadeIn>
        <FadeIn>
            <div className="testimonial">
                <div className="quote-marks">&#8220;</div>
                <div className="quote">
                    <p>
                        <i>
                            Our family adopted a rescue dog, Annie, back in
                            September and we quickly found out she was very
                            aggressive to other dogs. She was also resource
                            guarding her food and her space. Laura was
                            recommended to us by the SPCA as we wanted to see if
                            Annie could be trained to be around other dogs and
                            be trusted with our 4 kids. Laura is one of those
                            people who is meant to be working with dogs. Right
                            from the beginning with a few tips, Annie had
                            calmed. Laura was able to explain Annie's
                            personality and tailor a training plan to her needs.
                            We did weekly group training sessions and then Annie
                            went for 12 days in her 'Board and Train' program.
                            Annie can now be around other dogs, she is on the
                            left in the picture below. She has also learned her
                            place in the family. Any one of our kids can go up
                            and hug her while she is eating her food. Annie is
                            adored and loved and it was thanks to Laura's
                            expertise as a dog trainer.
                        </i>
                    </p>
                </div>
            </div>
        </FadeIn>
        <FadeIn>
            <div className="testimonial">
                <div className="quote-marks">&#8220;</div>
                <div className="quote">
                    <p>
                        <i>
                            We did the board and train with Laura to help our 5
                            year old work on his dog aggression and
                            socialization. We had trainers in the past but still
                            had difficulty controlling him. After he was with
                            Laura for a week he learned new techniques that
                            enabled him to walk in city park. Not only did Rudy
                            learn but Laura spent time with us to teach us how
                            to control him and make him a calmer and more
                            sociable dog. Thanks again Laura!
                        </i>
                    </p>
                </div>
            </div>
        </FadeIn>
    </div>
)

export default Testimonials
