import React from 'react'

import FadeIn from './fade-in'

const FAQ = () => (
    <div id="faq">
        <div className="faq__section">
            <FadeIn>
                <h2>FAQ</h2>
            </FadeIn>

            <FadeIn>
                <p>
                    <b>Where are we located?</b> On a beautiful 6 acre property
                    in Black Mountain.
                </p>
            </FadeIn>

            <FadeIn>
                <p>
                    <b>Where does my dog sleep?</b> All dogs are allocated size
                    appropriate crates in a heated/air conditioned building on
                    our property. The building is secure and is armed with
                    camera and smoke detector. There is someone on our property
                    at all times. We have two specifically built steel
                    indestructible kennels for escape artists, strong dogs, and
                    less dog friendly dogs.
                </p>
            </FadeIn>

            <FadeIn>
                <p>
                    <b>
                        Can I come see my dog through out their training week?
                    </b>{' '}
                    Your dog has come to train with us, and a part of the
                    training process is teaching you new ways to interact with
                    your dog. Having people stop by and visit their dogs through
                    the week with out training their human will backset the
                    progress we are making with your dogs new behaviours and
                    obedience, as well as taking up training time from your
                    dog’s week. Therefore we ask that you give your dog the best
                    chance to succeed by refraining from visiting. However, we
                    are more than happy for you to call and e-mail for updates,
                    as well as we will post video’s and pics on FB and IG to
                    track your dog through the week.{' '}
                </p>
            </FadeIn>

            <FadeIn>
                <p>
                    <b>
                        Can I come meet you before I bring my dog for a board
                        and train?
                    </b>{' '}
                    Absolutely, please email us to book a time.
                </p>
            </FadeIn>

            <FadeIn>
                <p>
                    <b>What should I bring?</b> You will receive a specific list
                    of items to bring in your booking email.
                </p>
            </FadeIn>

            <FadeIn>
                <p>
                    <b>What is the dog/trainer ratio?</b> We have two full time
                    trainers. Each trainer works with 4 dogs per week. This
                    allows for plenty of training and affection while monitoring
                    their progress, happiness and well being. As well as
                    allowing for a strong relationship between trainer and their
                    dog.
                </p>
            </FadeIn>

            <FadeIn>
                <p>
                    <b>How many dogs do you take at a time?</b> 8 dogs a week,
                    allowing 4 dogs per trainer.
                </p>
            </FadeIn>

            <FadeIn>
                <p>
                    <b>Will my dog socialize with aggressive dogs?</b> Dogs are
                    dogs and sometimes even the most easy going dogs can have a
                    disagreement. We monitor all dog/dog interactions. Dogs that
                    we deem aggressive will only socialize with our pack.
                </p>
            </FadeIn>

            <FadeIn>
                <p>
                    <b>How often is my dog out training?</b> Every dog is
                    different and we customize training to each dog and their
                    needs, whether it’s multiple short training sessions or a
                    few long training sessions. Either way at the end of each
                    day every dog is mentally and physically exhausted and ready
                    for rest.
                </p>
            </FadeIn>

            <FadeIn>
                <p>
                    <b>Will my dog miss me?</b> Your dog will be stoked to see
                    you for your Friday lessons and go home with you. However,
                    clients tell us time and time again how excited there dog is
                    to return on Monday as they drive down our street.
                </p>
            </FadeIn>

            <FadeIn>
                <p>
                    <b>What age does my dog need to be to join training?</b>{' '}
                    Typically 3.5 to 4 months. All dogs coming onto our property
                    need to have their first 3 sets of vaccinations.
                </p>
            </FadeIn>

            <FadeIn>
                <p>
                    <b>Is my dog too old to train?</b> You CAN teach an old dog
                    new tricks. We have worked with mature and senior dogs as
                    long as they are physically well enough to train.
                </p>
            </FadeIn>

            <FadeIn>
                <p>
                    <b>What vaccinations does my dog need?</b> Kennel Cough,
                    Parvo, Distemper and Rabies.
                </p>
            </FadeIn>

            <FadeIn>
                <p>
                    <b>Will my dog behave for me when they come home?</b> All
                    our programs are designed to ensure you have success after
                    leaving our facility. See board and train descriptions for
                    more info.
                </p>
            </FadeIn>
        </div>

        <div className="faq__section">
            <FadeIn>
                <h3>E-collar FAQ</h3>
            </FadeIn>

            <FadeIn>
                <p>
                    <b>Why e-collar training?</b> This training gives you the
                    ability to communicate with your dog off-leash. Allowing
                    both you and your dog freedom and safety.
                </p>
            </FadeIn>

            <FadeIn>
                <p>
                    <b>Why does e-collar training take 2 weeks?</b> After doing
                    this for years, we have created a program that respects the
                    dog and train in a humane and kind fashion. This allows the
                    dog to fully understand the new commands and communication
                    on the e-collar. Our goal is for you to leave after two
                    weeks with a dog who is trained properly on the collar.
                </p>
            </FadeIn>

            <FadeIn>
                <p>
                    <b>Can all dogs be trained onto an e-collar?</b> Not all
                    dogs are suitable for e-collar training, this largely
                    depends on age and size, however you may be surprised at how
                    many can really benefit from the training. 100% recall is a
                    favourite!
                </p>
            </FadeIn>

            <FadeIn>
                <p>
                    <b>Will you train e-collar in private lessons?</b> We
                    promote humane training, and in order to teach a dog
                    communication on the e-collar it is important for the
                    handler to be well versed in timing and when to increase
                    pressure and stimulation. The e-collar is a wonderful tool
                    when used with knowledge and care. It is our opinion that
                    the initial training be applied by a professional that has
                    experience in training dogs onto the e-collar.
                </p>
            </FadeIn>

            <FadeIn>
                <p>
                    <b>Will my e-collar make my dog aggressive?</b> Like
                    anything, if your dog does not understand what or why a
                    negative is happening to them, it can definitely make things
                    worse. This is why we take the time to ensure the dogs fully
                    understand the communication of the e-collar and where it is
                    coming from. Proper training on the e-collar will give you
                    and your dog safety and freedom, which is really important
                    for a dogs mental health.
                </p>
            </FadeIn>
        </div>
    </div>
)

export default FAQ
