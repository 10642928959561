import React from 'react'

const Paw = () => (
    <svg
        className="big-paw"
        viewBox="0 0 284 382"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M137.247148,199.894515 C171.022814,199.894515 177.213891,231.643647 180.673004,239.873418 C195.684411,275.587904 234.285171,303.30661 228.387833,335.289733 C228.387833,335.289733 224.412644,351.548639 217.129278,358.21097 C190.323194,382.731364 194.202703,354.10277 201.581749,345.950774 C211.231939,335.289733 189.68284,318.737563 186.570342,331.558368 C182.81749,347.016878 186.727935,347.016878 173.1673,347.016878 C160.30038,347.016878 163.159076,358.788289 169.414449,360.343179 C190.859316,365.673699 192.068988,384.714919 171.022814,376.867792 C141,365.673699 130.813688,358.21097 119.555129,358.21097 C108.346877,358.21097 69.1596956,376.867792 50.9315589,353.413502 C32.7034222,329.959212 53.0760457,305.971871 57.9011407,298.509142 C62.7262357,291.046414 110.971842,252.119313 121.69962,248.40225 C157.083653,236.142053 152.814961,286.401979 146.361219,294.244726 C141.536127,300.108298 125.452474,308.637172 119.555133,308.637131 C105.266031,308.637031 102.512131,300.098472 104.007603,297.97609 C105.317609,296.116924 110.115357,301.704872 116.874527,301.707454 C121.495481,301.70922 135.429788,286.527649 113.121673,284.116737 C88.4600747,281.451477 87.9092678,309.500823 94.8935361,314.500703 C121.699622,333.690577 137.745697,317.660476 141,315.566807 C177.456276,292.112518 169.69538,272.294893 165.125475,260.129395 C148.505706,215.886076 122.436721,228.690486 108.296578,240.40647 C98.6463865,248.40225 93.4613293,250.018116 85.7794677,261.195499 C63.7984798,293.178622 68.7064013,267.61731 72.3764259,257.997187 C78.2737629,242.538678 82.2445945,230.474837 94.8935361,215.886076 C108.296578,200.427567 121.69962,199.894515 137.247148,199.894515 Z"
            id="palm"
            strokeWidth="2.667168"
        ></path>
        <path
            d="M1.60836498,186.035161 C2.14448661,184.436005 4.68567165,170.028853 14.4752855,157.783404 C31.095058,136.994374 41.1772239,150.191239 45.5703422,155.651195 C56.292775,168.977497 67.4254713,201.500939 67.5513308,204.691983 C68.6235735,231.877633 61.11787,237.741206 58.4372624,242.005626 C54.3933071,248.438915 52.3147411,253.5642 48.2509506,255.331927 C39.6730031,259.063287 43.587113,253.699196 46.1064639,252.133615 C50.3954366,249.468351 45.5298077,245.676624 41.2813681,244.670882 C30.022813,242.005622 39.1387876,228.364865 43.4258555,228.146273 C64.3346001,227.080165 62.8909409,212.002676 62.1901134,207.357243 C58.9733833,186.035162 51.4889647,190.26518 42.3536115,190.299578 C29.4866913,190.348027 31.7071733,178.485972 38.0646388,180.171589 C46.1064632,182.303798 43.017381,193.387904 46.6425856,180.171589 C48.2509499,174.308017 38.1012798,166.004921 32.7034221,168.444444 C20.9087446,173.774965 19.9396464,183.298341 20.9087452,187.634318 C23.0532313,197.229255 39.1368808,193.49789 45.5703415,196.696203 C48.7870716,198.295359 55.4568543,202.037365 55.756654,209.489451 C56.292775,222.815749 42.3536115,227.080172 36.4562738,227.080169 C29.5042749,227.080165 27.3422046,240.939518 29.4866913,245.203934 C31.631178,249.468351 38.0646381,255.331923 38.0646381,257.997183 C38.0646381,260.662443 32.7892532,260.534526 29.4866913,259.596339 C18.2281372,256.398027 1.07224335,239.873414 3.65338967e-09,214.819968 C-0.113768794,212.161709 1.07224336,187.634317 1.60836498,186.035161 Z"
            id="far-left"
            strokeWidth="1.0668672"
        ></path>
        <path
            d="M110.441065,57.5696203 C128.13308,60.2348805 128.714082,81.0028142 130.277567,89.5527426 C133.494297,107.143459 130.711619,130.127743 130.277567,133.796065 C127.060837,160.981719 125.222797,148.273209 123.307985,145.523207 C118.48289,138.593533 105.519303,148.240891 99.7186312,151.386779 C82.026616,160.981719 81.0986919,132.178382 87.3878327,124.734177 C98.6463878,111.407875 95.9160752,111.36198 99.7186312,101.279887 C102.935361,92.7510536 110.985255,101.605843 108.296578,111.407876 C106.688213,117.271447 105.157359,120.245408 102.935361,122.601969 C94.8935361,131.130804 105.412646,127.521942 108.8327,123.668073 C116.874525,114.606187 118.523016,106.07694 118.48289,101.279887 C118.344667,84.755275 110.686436,77.4351888 105.079848,76.7594937 C87.3878327,74.6272863 85.5661132,89.718642 86.3155894,93.8171589 C89.5323194,111.407875 78.657103,122.074442 76.1292776,124.201125 C62.1901141,135.928273 71.1081965,148.561438 73.9847909,153.518987 C82.026616,167.378343 99.7503026,164.823512 105.079848,160.981716 C126.524715,145.52321 123.996312,159.385097 122.771863,162.580872 C114.193916,184.96906 110.316258,184.321589 105.61597,186.035162 C89.5323194,191.898737 71.35458,178.493238 68.6235741,175.907173 C57.365019,165.246135 51.3562224,137.409303 49.8593156,125.800281 C47.1787072,105.01125 53.3453682,94.1277671 55.756654,88.4866385 C64.8707226,67.1645567 74.0912097,65.5019173 82.0266166,60.7679325 C87.3878313,57.5696203 100.474651,56.0682037 110.441065,57.5696203 Z"
            id="left"
            strokeWidth="1.0668672"
        ></path>
        <path
            d="M186.570342,57.0365682 C166.73384,52.7721519 158.109135,76.7319164 154.939163,82.0900141 C149.577947,91.1519004 142.755621,117.908619 141.536122,123.668072 C139.391635,133.796061 138.855513,156.184246 141.536122,166.845287 C144.752852,179.638536 150.114068,182.303796 157.619772,186.035162 C170.919516,191.365681 165.089849,186.212898 163.51711,185.50211 C157.619772,182.836848 165.140532,180.328695 167.806084,179.105484 C174.775665,175.907172 170.765819,164.420114 163.51711,159.915611 C154.939163,154.58509 152.568524,140.704521 152.258555,130.59775 C151.555573,107.676511 170.715403,117.498851 173.1673,119.936709 C182.281369,128.998592 198.780833,132.120502 202.117871,113.540084 C204.798479,98.6146262 193.003802,93.2841071 189.787072,93.2841069 C171.116013,93.2841058 183.576386,81.0293172 184.961977,79.9578059 C189.787072,76.2264426 197.841828,59.4596923 186.570342,57.0365682 Z"
            id="right-left"
            strokeWidth="1.0668672"
        ></path>
        <path
            d="M206.406844,65.0323488 C192.467681,55.9704641 200.545311,74.8321848 202.653992,79.4247539 C213.912548,103.945149 204.13573,132.700833 198.365019,135.92827 C189.787072,140.725735 170.828412,125.288374 164.589354,139.126582 C157.619772,154.585088 172.559147,162.642294 180.136882,165.779184 C195.589095,172.175805 183.80495,180.025128 179.064639,181.770745 C164.589354,187.101262 174.70165,190.671405 179.064639,190.299574 C197.828897,188.700415 212.977697,163.288982 216.057034,158.849508 C226.779468,143.390995 230.193759,105.394872 228.387833,97.0154712 C225.171103,82.0900145 218.943761,73.1826293 206.406844,65.0323488 Z"
            id="right-right"
            strokeWidth="1.0668672"
        ></path>
        <path
            d="M239.110266,152.452883 C253.04943,144.457103 257.874525,154.052039 267.524715,166.845288 C282.536122,189.233475 283.088299,215.790291 280.927757,228.679325 C272.885932,276.654009 241.177135,280.385373 224.634981,269.724332 C190.628006,247.807611 200.159867,221.787551 202.653992,213.220816 C208.551331,192.964839 208.786851,191.527616 217.129278,199.361463 C226.779467,208.423348 217.171685,219.490946 216.057034,222.815752 C208.015209,246.803095 220.835939,255.510451 225.171103,257.997187 C253.049429,273.988749 267.379724,235.578804 266.988593,229.745429 C265.380228,205.758088 243.399239,174.84107 242.326996,173.241914 C240.34979,170.293086 233.212927,162.047819 230.532319,159.915612 C227.851711,157.783404 235.543064,154.499101 239.110266,152.452883 Z"
            id="far-right-outer"
            strokeWidth="1.0668672"
        ></path>
        <path
            d="M226.779468,165.779184 C221.954373,165.779184 213.405938,188.5423 214.448669,189.233474 C219.273764,192.431786 230.612813,186.457715 230.532319,202.559775 C230.471031,214.819972 221.472961,232.976399 223.026616,241.472574 C226.187363,258.757147 240.182512,256.931083 247.688213,250.001406 C255.193915,243.07173 259.482888,228.146273 259.482888,225.481013 C262.699618,215.886076 249.296576,195.097046 248.224335,193.49789 C248.224335,193.49789 228.387833,165.779184 226.779468,165.779184 Z"
            id="far-right-inner"
            strokeWidth="1.0668672"
        ></path>
        <path
            d="M37.5285171,120.679011 C37.5285171,111.940929 41.8174905,101.588665 37.5285171,101.279887 C30.2304539,101.588665 13.9391635,132.729958 15.0114068,134.862166 C16.0836502,136.994374 37.5285162,137.527426 39.1368812,136.461322 C42.9988057,133.901447 37.5285171,129.417093 37.5285171,120.679011 Z"
            id="far-left-nail"
            strokeWidth="1.0668672"
        ></path>
        <path
            d="M132.422053,-4.95942852e-08 C121.608492,-3.39525698 92.7490494,39.4458515 92.7490494,43.1772152 C92.7490494,45.8424761 117.239707,49.2493731 121.163498,46.9085795 C123.844106,45.3094241 122.026563,34.8970743 123.844106,26.1195499 C127.596958,7.99578041 142.608365,3.19831226 132.422053,-4.95942852e-08 Z"
            id="left-nail"
            strokeWidth="1.0668672"
        ></path>
        <path
            d="M154.939163,4.79746835 C149.041825,8.52883253 162.444867,8.52883241 160.836502,34.6483826 C160.836502,34.6483826 158.123718,47.3776466 159.228137,49.5738384 C160.836502,52.7721507 187.066808,51.6546363 188.178708,50.1068905 C190.859316,46.3755294 165.997691,-2.19948376 154.939163,4.79746835 Z"
            id="right-nail"
            strokeWidth="0.7776"
        ></path>
        <path
            d="M242.863118,104.4782 C239.462083,105.740173 245.543726,106.950129 245.543726,123.668073 C245.543726,130.064697 239.646388,136.994373 241.254753,139.126582 C242.863118,141.258792 265.805156,146.272439 267.524715,144.990155 C269.669201,143.391 254.355774,100.213783 242.863118,104.4782 Z"
            id="Path-331"
            strokeWidth="1.0668672"
        ></path>
    </svg>
)

export default Paw
