import React from 'react'

import Boarding from './boarding'
import Obedience from './obedience'
import ServicesOverview from './services-overview'

const Services = () => (
    <div id="services">
        <ServicesOverview />
        <Obedience />
        <Boarding />
    </div>
)

export default Services
