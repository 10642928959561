import React from 'react'

import FadeIn from '../fade-in'

const Obedience = () => (
    <div id="obedience" className="options">
        <div id="groupClasses" className="flex">
            <div id="beginner" className="option">
                <FadeIn>
                    <h3>Obedience Classes</h3>
                    <hr />
                    <p>
                        Obedience will help you obtain leadership and teach you
                        to communicate effectively with your dog using
                        motivation praise and correction making you the pack
                        leader. In the seven-week class you will learn:
                    </p>
                    <ul>
                        <li>Heel + sit @ heel</li>
                        <li>Down + sit @ heel</li>
                        <li>Down + sit from front</li>
                        <li>Recall</li>
                        <li>Place</li>
                        <li>Figure 8 + toy work</li>
                        <li>Leave it + review it + graduation &#x1F60A;</li>
                    </ul>
                    <p>
                        <b>$360 for 7 weeks</b> of group lessons and one private
                        session
                    </p>
                </FadeIn>
            </div>

            <div id="advanced" className="option">
                <FadeIn>
                    <h3>Advanced Obedience</h3>
                    <hr />
                    <p>
                        Advanced obedience will help cement and refine
                        everything learned in beginner as well as learning new
                        exciting moves. This is a revolving eight week class
                        that you can register for as many times as you like. 
                        Some of the things we will cover are:
                    </p>
                    <ul>
                        <li>Heel from away</li>
                        <li>Tab + body tie</li>
                        <li>Running and moving down</li>
                        <li>Moving stop commands down, sit, stand</li>
                        <li>Mark</li>
                        <li>Turning swing</li>
                        <li>Finish</li>
                        <li>Side switch</li>
                    </ul>
                    <p>
                        <b>$360 for 8 weeks</b>
                    </p>
                </FadeIn>
            </div>
        </div>

        <div id="oneOnOne" className="option">
            <FadeIn>
                <h3>One-on-One Training Lesson - $150/hour</h3>
                <hr />
                <div className="flex">
                    <div>
                        <h4>Who is this for:</h4>
                        <p>
                            For anyone who is looking to get some direction on
                            the smaller issues such as leash control, stays,
                            downs, cheeky puppy behaviour, recall, jumping, or
                            nipping. It’s also great for owners who want to
                            learn how to teach their dog obedience without the
                            group setting.
                        </p>
                    </div>
                    <div>
                        <h4>What is included:</h4>
                        <p>
                            You will talk about what behaviours you would like
                            to change or add and then undertake a customized
                            training session with you, your dog, and a
                            professional trainer. You will also gain general
                            knowledge and skills to build a more cohesive
                            relationship with your dog.
                        </p>
                    </div>
                </div>
            </FadeIn>
        </div>
    </div>
)

export default Obedience
