import React from 'react'
import Slider from 'react-slick'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const sliderSettings = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    infinite: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
}

const Media = () => {
    const data = useStaticQuery(
        graphql`{
            award: file(relativePath: {eq: "award.png"}) {
                childImageSharp {
                    gatsbyImageData(width: 400, layout: CONSTRAINED)
                }
            }
            photos: allFile(
                filter: {extension: {regex: "/(jpg)|(png)|(jpeg)/"}, relativeDirectory: {eq: "slideshow"}}
            ) {
                edges {
                    node {
                        base
                        childImageSharp {
                            gatsbyImageData(width: 640, layout: CONSTRAINED)
                        }
                    }
                }
            }
        }`
    )

    return (
        <div id="media">
            <div id="award">
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://threebestrated.ca/dog-trainers-in-kelowna-bc"
                    style={{ display: 'block', maxWidth: 900, margin: 'auto' }}
                >
                    <img
                        width="300"
                        src="https://threebestrated.ca/awards/dog_trainers-kelowna-2020-clr.svg"
                        alt="Best Dog trainers in Kelowna"
                    />
                    <img
                        width="300"
                        src="https://threebestrated.ca/awards/dog_trainers-kelowna-2021-clr.svg"
                        alt="Best Dog trainers in Kelowna"
                    />
                    <img
                        width="300"
                        src="https://threebestrated.ca/awards/dog_trainers-kelowna-2022-clr.svg"
                        alt="Best Dog trainers in Kelowna"
                    />
                </a>
            </div>

            <Slider {...sliderSettings}>
                {data.photos.edges.map((image, index) => (
                    <GatsbyImage
                        image={image.node.childImageSharp.gatsbyImageData}
                        alt={image.node.base.split('.')[0]}
                        key={index}
                        loading="eager"
                        imgStyle={{ objectFit: 'contain' }}
                    />
                ))}
            </Slider>
        </div>
    );
}

export default Media
