import React from 'react'

import About from '../components/about'
import Contact from '../components/contact'
import Hero from '../components/hero'
import Layout from '../components/layout'
import Media from '../components/media'
import Mission from '../components/mission'
import Services from '../components/services'
import Faq from '../components/faq'
import Testimonials from '../components/testimonials'

import '../styles/styles.scss'

const IndexPage = () => (
    <Layout>
        <Hero />
        <Mission />
        <div className="content">
            <div className="contentWrapper">
                <Media />
                <Services />
                <Faq />
                <Testimonials />
                <About />
            </div>
        </div>
        <Contact />
    </Layout>
)

export default IndexPage
