import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";

import FadeIn from './fade-in'

const About = () => {
    const data = useStaticQuery(
        graphql`{
            profile: file(relativePath: {eq: "profile.jpg"}) {
                childImageSharp {
                    gatsbyImageData(width: 300, layout: CONSTRAINED)
                }
            }
        }`
    )

    return (
        <div id="about">
            <div className="about__container">
                <FadeIn>
                    <div className="about__picture">
                        <GatsbyImage
                            image={data.profile.childImageSharp.gatsbyImageData}
                            alt="Laura and Jack the pitbull" />
                    </div>
                </FadeIn>
                <FadeIn>
                    <div className="about__bio">
                        <h2>A Little About Me</h2>
                        <p>
                            Born and raised in Kelowna, B.C., I grew up on a
                            farm in the beautiful Okanagan surrounded by
                            animals. Like all young girls my initial passion was
                            with horses. I hold a certification in Equine
                            Massage Therapy which I still practice and can apply
                            to our canine friends. I have bred and raised CKC
                            registered English Springer Spaniel's and Yorkshire
                            Terrier's, but my true passion for dog training
                            began when I adopted a Pitbull mix rescue from LA.
                            He had burned his way through three foster homes
                            with his out-of-control behavior and found himself
                            awaiting the right owner to come help him with his
                            wayward ways i.e. me. Dealing and training this new
                            fur friend of mine developed a fascination for the
                            canine mind true pack leadership and balance. The
                            true realization that our dogs are happy and relaxed
                            when they know how to behave and what is expected of
                            them became critically clear. An obedient dog will
                            fully respect and always love you. I have since
                            taken a formal education becoming a Certified Master
                            Dog Trainer and Behaviourist, and have enjoyed
                            working with all types of dogs and their humans. My
                            method of training dogs consists of motivation
                            praise and correction, while tailoring to each dog
                            specific needs and always respecting the dignity and
                            capabilities of handler and dog. I look forward to
                            answering any questions you may have.
                        </p>
                    </div>
                </FadeIn>
            </div>
        </div>
    );
}

export default About
