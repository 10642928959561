import React from 'react'

const Paws = () => (
    <svg
        className="prints"
        viewBox="0 0 360 324"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="1" opacity="0" transform="translate(326.000000, 302.000000)">
            <path
                d="M16.1949674,9.08666998 C21.8037491,6.58513375 28.5764282,6.58513375 31.1450294,9.08666998 C33.4828579,11.2952715 31.1450294,15.4153248 25.305475,17.6976868 C19.4659206,20.1992231 12.9273588,20.2729836 10.1246402,17.7714474 C7.55269443,15.4890854 10.355413,11.4427926 16.1949674,9.08666998 L16.1949674,9.08666998 Z"
                id="Fill-1"
                fill="#000000"
            ></path>
            <path
                d="M16.1949674,9.08666998 C21.8037491,6.58513375 28.5764282,6.58513375 31.1450294,9.08666998 C33.4828579,11.2952715 31.1450294,15.4153248 25.305475,17.6976868 C19.4659206,20.1992231 12.9273588,20.2729836 10.1246402,17.7714474 C7.55269443,15.4890854 10.355413,11.4427926 16.1949674,9.08666998 L16.1949674,9.08666998 Z"
                id="Stroke-2"
            ></path>
            <path
                d="M1.48236724,11.2952715 C2.88372652,10.7051871 5.33526912,11.1477504 6.97074571,12.1782906 C7.78681173,13.428005 7.55269443,15.0486295 5.92056238,15.6366065 C4.0509685,16.445865 1.71314001,16.1508228 0.545898031,14.9011084 C-1.08623402,13.6492866 -0.621343946,12.10453 1.48236724,11.2952715 L1.48236724,11.2952715 Z"
                id="Fill-3"
                fill="#000000"
            ></path>
            <path
                d="M1.48236724,11.2952715 C2.88372652,10.7051871 5.33526912,11.1477504 6.97074571,12.1782906 C7.78681173,13.428005 7.55269443,15.0486295 5.92056238,15.6366065 C4.0509685,16.445865 1.71314001,16.1508228 0.545898031,14.9011084 C-1.08623402,13.6492866 -0.621343946,12.10453 1.48236724,11.2952715 L1.48236724,11.2952715 Z"
                id="Stroke-4"
            ></path>
            <path
                d="M6.03762103,5.11203027 C7.55269443,4.30277171 10.1246402,4.81909553 11.5259995,6.14257047 C12.6932415,7.17311067 12.1079482,8.71786725 10.5895303,9.38171216 C8.95405371,10.2647313 6.38545245,9.89592854 5.10115182,8.6441067 C3.93390984,7.39439231 4.16802715,5.77587519 6.03762103,5.11203027 L6.03762103,5.11203027 Z"
                id="Fill-5"
                fill="#000000"
            ></path>
            <path
                d="M6.03762103,5.11203027 C7.55269443,4.30277171 10.1246402,4.81909553 11.5259995,6.14257047 C12.6932415,7.17311067 12.1079482,8.71786725 10.5895303,9.38171216 C8.95405371,10.2647313 6.38545245,9.89592854 5.10115182,8.6441067 C3.93390984,7.39439231 4.16802715,5.77587519 6.03762103,5.11203027 L6.03762103,5.11203027 Z"
                id="Stroke-6"
            ></path>
            <path
                d="M13.2785347,1.36077965 C15.1447841,0.696934739 17.4826126,1.13949801 18.8839718,2.24379876 C20.0512138,3.5672737 19.8170965,5.11203027 18.2986786,5.77587519 C16.4290847,6.58513375 13.8604835,6.21633102 12.5761828,4.89285608 C11.5259995,3.71479479 11.7567723,2.09627767 13.2785347,1.36077965 L13.2785347,1.36077965 Z"
                id="Fill-7"
                fill="#000000"
            ></path>
            <path
                d="M13.2785347,1.36077965 C15.1447841,0.696934739 17.4826126,1.13949801 18.8839718,2.24379876 C20.0512138,3.5672737 19.8170965,5.11203027 18.2986786,5.77587519 C16.4290847,6.58513375 13.8604835,6.21633102 12.5761828,4.89285608 C11.5259995,3.71479479 11.7567723,2.09627767 13.2785347,1.36077965 L13.2785347,1.36077965 Z"
                id="Stroke-8"
            ></path>
            <path
                d="M23.9041157,0.844455831 C25.6566509,0.108957816 27.8774208,0.623174194 29.2754355,1.65371439 C30.6767948,2.97929677 30.3289634,4.4502928 28.4593695,5.18579082 C26.7068343,6.14257047 24.3723503,5.70211464 23.0880497,4.37653226 C21.8037491,3.27223151 22.0345218,1.57995385 23.9041157,0.844455831 L23.9041157,0.844455831 Z"
                id="Fill-9"
                fill="#000000"
            ></path>
            <path
                d="M23.9041157,0.844455831 C25.6566509,0.108957816 27.8774208,0.623174194 29.2754355,1.65371439 C30.6767948,2.97929677 30.3289634,4.4502928 28.4593695,5.18579082 C26.7068343,6.14257047 24.3723503,5.70211464 23.0880497,4.37653226 C21.8037491,3.27223151 22.0345218,1.57995385 23.9041157,0.844455831 L23.9041157,0.844455831 Z"
                id="Stroke-10"
            ></path>
        </g>
        <g id="2" opacity="0" transform="translate(273.000000, 304.000000)">
            <path
                d="M16.5252635,8.77894764 C22.4818766,6.35117196 29.137497,6.27741141 31.7060983,8.7051871 C34.2780441,10.9875491 31.4753255,15.0338419 25.8665439,17.4637251 C20.030334,19.8915007 13.371369,19.8915007 10.6857091,17.4637251 C8.11710783,15.181363 10.9198264,11.1329628 16.5252635,8.77894764 L16.5252635,8.77894764 Z"
                id="Fill-11"
                fill="#000000"
            ></path>
            <path
                d="M16.5252635,8.77894764 C22.4818766,6.35117196 29.137497,6.27741141 31.7060983,8.7051871 C34.2780441,10.9875491 31.4753255,15.0338419 25.8665439,17.4637251 C20.030334,19.8915007 13.371369,19.8915007 10.6857091,17.4637251 C8.11710783,15.181363 10.9198264,11.1329628 16.5252635,8.77894764 L16.5252635,8.77894764 Z"
                id="Stroke-12"
            ></path>
            <path
                d="M1.69226016,11.1329628 C3.44479539,10.3974648 5.896338,10.840028 6.94986586,12.0918499 C8.35122514,13.1940432 8.00004918,14.6671467 6.48163125,15.4026447 C4.49497872,16.1381427 2.27755341,15.6976868 0.993252786,14.5933861 C-0.291047842,13.4890854 -0.0602750733,11.8705682 1.69226016,11.1329628 L1.69226016,11.1329628 Z"
                id="Fill-13"
                fill="#000000"
            ></path>
            <path
                d="M1.69226016,11.1329628 C3.44479539,10.3974648 5.896338,10.840028 6.94986586,12.0918499 C8.35122514,13.1940432 8.00004918,14.6671467 6.48163125,15.4026447 C4.49497872,16.1381427 2.27755341,15.6976868 0.993252786,14.5933861 C-0.291047842,13.4890854 -0.0602750733,11.8705682 1.69226016,11.1329628 L1.69226016,11.1329628 Z"
                id="Stroke-14"
            ></path>
            <path
                d="M6.5986899,5.02558958 C8.11710783,4.21633102 10.6857091,4.43761266 12.0870684,5.76319504 C13.1372517,7.01290943 12.9064789,8.557666 11.1539437,9.22151092 C9.51846711,10.0307695 6.83280721,9.51444566 5.6622207,8.26473127 C4.37792007,7.16043052 4.72909602,5.61567395 6.5986899,5.02558958 L6.5986899,5.02558958 Z"
                id="Fill-15"
                fill="#000000"
            ></path>
            <path
                d="M6.5986899,5.02558958 C8.11710783,4.21633102 10.6857091,4.43761266 12.0870684,5.76319504 C13.1372517,7.01290943 12.9064789,8.557666 11.1539437,9.22151092 C9.51846711,10.0307695 6.83280721,9.51444566 5.6622207,8.26473127 C4.37792007,7.16043052 4.72909602,5.61567395 6.5986899,5.02558958 L6.5986899,5.02558958 Z"
                id="Stroke-16"
            ></path>
            <path
                d="M14.0737209,1.27223151 C15.705853,0.610494045 18.0436814,0.905536228 19.3279821,2.08359752 C20.6122827,3.11203027 20.3781654,4.65889429 18.7460333,5.61567395 C16.7593808,6.27741141 14.4215523,5.83484814 13.2543103,4.58513375 C11.7358924,3.18579082 12.3211857,1.78855533 14.0737209,1.27223151 L14.0737209,1.27223151 Z"
                id="Fill-17"
                fill="#000000"
            ></path>
            <path
                d="M14.0737209,1.27223151 C15.705853,0.610494045 18.0436814,0.905536228 19.3279821,2.08359752 C20.6122827,3.11203027 20.3781654,4.65889429 18.7460333,5.61567395 C16.7593808,6.27741141 14.4215523,5.83484814 13.2543103,4.58513375 C11.7358924,3.18579082 12.3211857,1.78855533 14.0737209,1.27223151 L14.0737209,1.27223151 Z"
                id="Stroke-18"
            ></path>
            <path
                d="M24.3514705,0.684254591 C26.1006612,-0.0512434243 28.4384897,0.243798759 29.7227903,1.5672737 C31.1241496,2.89285608 30.655915,4.21633102 29.0204384,5.02558958 C27.2712477,5.83484814 24.8163605,5.39439231 23.5320599,4.14257047 C22.3648179,2.96661663 22.5989352,1.41975261 24.3514705,0.684254591 L24.3514705,0.684254591 Z"
                id="Fill-19"
                fill="#000000"
            ></path>
            <path
                d="M24.3514705,0.684254591 C26.1006612,-0.0512434243 28.4384897,0.243798759 29.7227903,1.5672737 C31.1241496,2.89285608 30.655915,4.21633102 29.0204384,5.02558958 C27.2712477,5.83484814 24.8163605,5.39439231 23.5320599,4.14257047 C22.3648179,2.96661663 22.5989352,1.41975261 24.3514705,0.684254591 L24.3514705,0.684254591 Z"
                id="Stroke-20"
            ></path>
        </g>
        <g id="3" opacity="0" transform="translate(295.000000, 254.000000)">
            <path
                d="M16.3650633,8.37099057 C22.087559,5.79358635 28.9772968,5.72193325 31.4288394,8.14970893 C33.8837265,10.5774846 31.4288394,14.4783638 25.589285,16.9799 C19.7530751,19.3360226 13.0941101,19.3360226 10.4084502,16.9799 C8.19102488,14.773406 10.5255088,10.6512452 16.3650633,8.37099057 L16.3650633,8.37099057 Z"
                id="Fill-41"
                fill="#000000"
            ></path>
            <path
                d="M16.3650633,8.37099057 C22.087559,5.79358635 28.9772968,5.72193325 31.4288394,8.14970893 C33.8837265,10.5774846 31.4288394,14.4783638 25.589285,16.9799 C19.7530751,19.3360226 13.0941101,19.3360226 10.4084502,16.9799 C8.19102488,14.773406 10.5255088,10.6512452 16.3650633,8.37099057 L16.3650633,8.37099057 Z"
                id="Stroke-42"
            ></path>
            <path
                d="M1.64911856,10.5774846 C3.51871244,9.91574715 5.73613775,10.1370288 7.02043837,11.4605037 C8.304739,12.7123256 8.07396623,14.1833216 6.0873137,14.920927 C4.33477847,15.6543176 1.88323586,15.1401012 0.715993884,14.109561 C-0.685365396,12.8598467 -0.454592627,11.2413295 1.64911856,10.5774846 L1.64911856,10.5774846 Z"
                id="Fill-43"
                fill="#000000"
            ></path>
            <path
                d="M1.64911856,10.5774846 C3.51871244,9.91574715 5.73613775,10.1370288 7.02043837,11.4605037 C8.304739,12.7123256 8.07396623,14.1833216 6.0873137,14.920927 C4.33477847,15.6543176 1.88323586,15.1401012 0.715993884,14.109561 C-0.685365396,12.8598467 -0.454592627,11.2413295 1.64911856,10.5774846 L1.64911856,10.5774846 Z"
                id="Stroke-44"
            ></path>
            <path
                d="M6.20437235,4.39635087 C8.07396623,3.58709231 10.5255088,3.95589504 11.6927508,5.27936998 C12.9770514,6.45532382 12.6258755,7.9284273 10.8766848,8.59016476 C9.24120821,9.54694442 6.90337972,9.10648859 5.50536498,7.7809062 C4.10066116,6.53119181 4.80301307,4.98432779 6.20437235,4.39635087 L6.20437235,4.39635087 Z"
                id="Fill-45"
                fill="#000000"
            ></path>
            <path
                d="M6.20437235,4.39635087 C8.07396623,3.58709231 10.5255088,3.95589504 11.6927508,5.27936998 C12.9770514,6.45532382 12.6258755,7.9284273 10.8766848,8.59016476 C9.24120821,9.54694442 6.90337972,9.10648859 5.50536498,7.7809062 C4.10066116,6.53119181 4.80301307,4.98432779 6.20437235,4.39635087 L6.20437235,4.39635087 Z"
                id="Stroke-46"
            ></path>
            <path
                d="M13.796462,0.642992804 C15.5456527,-0.0925052109 18.0005398,0.276297519 19.1677818,1.52601191 C20.3350238,2.7040732 20.104251,4.24882978 18.4654299,4.98432779 C16.7162392,5.72193325 14.0272348,5.35313052 12.9770514,4.17506923 C11.6927508,2.77783375 12.0439268,1.37849082 13.796462,0.642992804 L13.796462,0.642992804 Z"
                id="Fill-47"
                fill="#000000"
            ></path>
            <path
                d="M13.796462,0.642992804 C15.5456527,-0.0925052109 18.0005398,0.276297519 19.1677818,1.52601191 C20.3350238,2.7040732 20.104251,4.24882978 18.4654299,4.98432779 C16.7162392,5.72193325 14.0272348,5.35313052 12.9770514,4.17506923 C11.6927508,2.77783375 12.0439268,1.37849082 13.796462,0.642992804 L13.796462,0.642992804 Z"
                id="Stroke-48"
            ></path>
            <path
                d="M23.9571529,0.0550158809 C25.9404609,-0.606721588 28.1612307,-0.240026303 29.6796487,0.938034988 C30.8468907,2.26150993 30.4957147,3.80837395 28.7431795,4.39635087 C27.1077029,5.35313052 24.6561603,4.91056725 23.254801,3.58709231 C21.9705004,2.48279156 22.3216763,0.790513896 23.9571529,0.0550158809 L23.9571529,0.0550158809 Z"
                id="Fill-49"
                fill="#000000"
            ></path>
            <path
                d="M23.9571529,0.0550158809 C25.9404609,-0.606721588 28.1612307,-0.240026303 29.6796487,0.938034988 C30.8468907,2.26150993 30.4957147,3.80837395 28.7431795,4.39635087 C27.1077029,5.35313052 24.6561603,4.91056725 23.254801,3.58709231 C21.9705004,2.48279156 22.3216763,0.790513896 23.9571529,0.0550158809 L23.9571529,0.0550158809 Z"
                id="Stroke-50"
            ></path>
        </g>
        <g id="4" opacity="0" transform="translate(242.000000, 255.000000)">
            <path
                d="M16.6920148,8.91574715 C22.6486279,6.63549256 29.5417102,6.48797146 31.9932528,8.8419866 C34.4447954,11.1960017 31.9932528,15.244402 26.1536984,17.6721777 C20.3141439,20.1020608 13.6585235,20.1758213 11.0865777,17.6721777 C8.51797646,15.3181625 11.0865777,11.2697623 16.6920148,8.91574715 L16.6920148,8.91574715 Z"
                id="Fill-51"
                fill="#000000"
            ></path>
            <path
                d="M16.6920148,8.91574715 C22.6486279,6.63549256 29.5417102,6.48797146 31.9932528,8.8419866 C34.4447954,11.1960017 31.9932528,15.244402 26.1536984,17.6721777 C20.3141439,20.1020608 13.6585235,20.1758213 11.0865777,17.6721777 C8.51797646,15.3181625 11.0865777,11.2697623 16.6920148,8.91574715 L16.6920148,8.91574715 Z"
                id="Stroke-52"
            ></path>
            <path
                d="M1.97941466,11.2697623 C3.84566401,10.5342643 6.30055115,10.9768275 7.46779313,12.1527814 C8.86915241,13.2570821 8.28385915,14.8755993 6.64838257,15.5394442 C4.89919187,16.2749422 2.44430473,15.9799 1.16000411,14.7301856 C-0.12095199,13.4783638 0.109820779,11.9336072 1.97941466,11.2697623 L1.97941466,11.2697623 Z"
                id="Fill-53"
                fill="#000000"
            ></path>
            <path
                d="M1.97941466,11.2697623 C3.84566401,10.5342643 6.30055115,10.9768275 7.46779313,12.1527814 C8.86915241,13.2570821 8.28385915,14.8755993 6.64838257,15.5394442 C4.89919187,16.2749422 2.44430473,15.9799 1.16000411,14.7301856 C-0.12095199,13.4783638 0.109820779,11.9336072 1.97941466,11.2697623 L1.97941466,11.2697623 Z"
                id="Stroke-54"
            ></path>
            <path
                d="M6.76544122,5.01486799 C8.75209376,4.42689107 10.9695191,4.72193325 12.2538197,5.97164764 C13.4244062,7.14970893 13.1902889,8.76822605 11.320695,9.50372407 C9.80562162,10.2413295 7.23367583,9.72500571 6.06643385,8.54694442 C4.66507457,7.37099057 5.13330917,5.750366 6.76544122,5.01486799 L6.76544122,5.01486799 Z"
                id="Fill-55"
                fill="#000000"
            ></path>
            <path
                d="M6.76544122,5.01486799 C8.75209376,4.42689107 10.9695191,4.72193325 12.2538197,5.97164764 C13.4244062,7.14970893 13.1902889,8.76822605 11.320695,9.50372407 C9.80562162,10.2413295 7.23367583,9.72500571 6.06643385,8.54694442 C4.66507457,7.37099057 5.13330917,5.750366 6.76544122,5.01486799 L6.76544122,5.01486799 Z"
                id="Stroke-56"
            ></path>
            <path
                d="M14.3575309,1.40903102 C15.8759488,0.673533002 18.3274914,1.11609628 19.6151366,2.29205012 C20.8994372,3.39635087 20.6653199,4.94110744 18.9127847,5.6787129 C17.2773081,6.41421092 14.5916482,5.97164764 13.4244062,4.79358635 C12.2538197,3.54387196 12.487937,1.92535484 14.3575309,1.40903102 L14.3575309,1.40903102 Z"
                id="Fill-57"
                fill="#000000"
            ></path>
            <path
                d="M14.3575309,1.40903102 C15.8759488,0.673533002 18.3274914,1.11609628 19.6151366,2.29205012 C20.8994372,3.39635087 20.6653199,4.94110744 18.9127847,5.6787129 C17.2773081,6.41421092 14.5916482,5.97164764 13.4244062,4.79358635 C12.2538197,3.54387196 12.487937,1.92535484 14.3575309,1.40903102 L14.3575309,1.40903102 Z"
                id="Stroke-58"
            ></path>
            <path
                d="M24.6352804,0.821054094 C26.270757,0.0834486352 28.8393583,0.526011911 30.0066003,1.77783375 C31.2909009,2.88213449 30.9397249,4.42689107 29.3075929,5.08862854 C27.5550576,5.97164764 25.2172292,5.53119181 23.8158699,4.35313052 C22.5315693,3.17506923 22.9998039,1.55655211 24.6352804,0.821054094 L24.6352804,0.821054094 Z"
                id="Fill-59"
                fill="#000000"
            ></path>
            <path
                d="M24.6352804,0.821054094 C26.270757,0.0834486352 28.8393583,0.526011911 30.0066003,1.77783375 C31.2909009,2.88213449 30.9397249,4.42689107 29.3075929,5.08862854 C27.5550576,5.97164764 25.2172292,5.53119181 23.8158699,4.35313052 C22.5315693,3.17506923 22.9998039,1.55655211 24.6352804,0.821054094 L24.6352804,0.821054094 Z"
                id="Stroke-60"
            ></path>
        </g>
        <g id="5" opacity="0" transform="translate(268.000000, 204.000000)">
            <path
                d="M16.036885,8.40348933 C21.7593808,5.9736062 28.5320599,5.89984566 30.9836025,8.25596824 C33.672607,10.7575045 30.9836025,14.7321442 25.2611067,17.2336804 C19.4215523,19.513935 12.7659319,19.6614561 9.96321332,17.2336804 C7.74244349,14.7321442 10.1973306,10.831265 16.036885,8.40348933 L16.036885,8.40348933 Z"
                id="Fill-61"
                fill="#000000"
            ></path>
            <path
                d="M16.036885,8.40348933 C21.7593808,5.9736062 28.5320599,5.89984566 30.9836025,8.25596824 C33.672607,10.7575045 30.9836025,14.7321442 25.2611067,17.2336804 C19.4215523,19.513935 12.7659319,19.6614561 9.96321332,17.2336804 C7.74244349,14.7321442 10.1973306,10.831265 16.036885,8.40348933 L16.036885,8.40348933 Z"
                id="Stroke-62"
            ></path>
            <path
                d="M1.32094034,10.7575045 C3.07347558,10.095767 5.29090088,10.4624623 6.69226016,11.6405236 C7.86284667,12.8185849 7.51167072,14.3633414 5.87619413,15.0988395 C4.00660025,15.8343375 1.55505765,15.4676422 0.387815668,14.3633414 C-0.782770842,13.0398665 -0.54865354,11.4192419 1.32094034,10.7575045 L1.32094034,10.7575045 Z"
                id="Fill-63"
                fill="#000000"
            ></path>
            <path
                d="M1.32094034,10.7575045 C3.07347558,10.095767 5.29090088,10.4624623 6.69226016,11.6405236 C7.86284667,12.8185849 7.51167072,14.3633414 5.87619413,15.0988395 C4.00660025,15.8343375 1.55505765,15.4676422 0.387815668,14.3633414 C-0.782770842,13.0398665 -0.54865354,11.4192419 1.32094034,10.7575045 L1.32094034,10.7575045 Z"
                id="Stroke-64"
            ></path>
            <path
                d="M5.99325279,4.42884963 C7.51167072,3.8408727 10.1973306,4.28132854 11.3645726,5.31186873 C12.6522178,6.56369057 12.5318146,8.0346866 10.6622207,8.77018462 C8.91303,9.50568263 6.57520151,9.28650844 5.05678358,8.0346866 C3.77248295,6.71121166 4.3577762,5.23810819 5.99325279,4.42884963 L5.99325279,4.42884963 Z"
                id="Fill-65"
                fill="#000000"
            ></path>
            <path
                d="M5.99325279,4.42884963 C7.51167072,3.8408727 10.1973306,4.28132854 11.3645726,5.31186873 C12.6522178,6.56369057 12.5318146,8.0346866 10.6622207,8.77018462 C8.91303,9.50568263 6.57520151,9.28650844 5.05678358,8.0346866 C3.77248295,6.71121166 4.3577762,5.23810819 5.99325279,4.42884963 L5.99325279,4.42884963 Z"
                id="Stroke-66"
            ></path>
            <path
                d="M13.3512251,0.823012655 C15.1004158,0.0875146402 17.555303,0.382556824 18.8396036,1.70603176 C19.8897869,2.9578536 19.6556696,4.42884963 18.1372517,5.23810819 C16.2710023,5.89984566 13.9331739,5.38562928 12.6522178,4.35508908 C11.3645726,3.03161414 11.7157486,1.48475012 13.3512251,0.823012655 L13.3512251,0.823012655 Z"
                id="Fill-67"
                fill="#000000"
            ></path>
            <path
                d="M13.3512251,0.823012655 C15.1004158,0.0875146402 17.555303,0.382556824 18.8396036,1.70603176 C19.8897869,2.9578536 19.6556696,4.42884963 18.1372517,5.23810819 C16.2710023,5.89984566 13.9331739,5.38562928 12.6522178,4.35508908 C11.3645726,3.03161414 11.7157486,1.48475012 13.3512251,0.823012655 L13.3512251,0.823012655 Z"
                id="Stroke-68"
            ></path>
            <path
                d="M23.8597475,0.235035732 C25.6122827,-0.500462283 27.7159939,-0.207527543 29.0002945,1.11805484 C30.6357711,2.29400868 30.1675365,3.91463325 28.5320599,4.57637072 C26.662466,5.31186873 24.3279821,5.01682655 23.0436814,3.8408727 C21.6423222,2.44152978 22.1105568,0.896773201 23.8597475,0.235035732 L23.8597475,0.235035732 Z"
                id="Fill-69"
                fill="#000000"
            ></path>
            <path
                d="M23.8597475,0.235035732 C25.6122827,-0.500462283 27.7159939,-0.207527543 29.0002945,1.11805484 C30.6357711,2.29400868 30.1675365,3.91463325 28.5320599,4.57637072 C26.662466,5.31186873 24.3279821,5.01682655 23.0436814,3.8408727 C21.6423222,2.44152978 22.1105568,0.896773201 23.8597475,0.235035732 L23.8597475,0.235035732 Z"
                id="Stroke-70"
            ></path>
        </g>
        <g id="6" opacity="0" transform="translate(215.000000, 206.000000)">
            <path
                d="M16.4808953,8.16952754 C22.3237942,5.52047022 29.2101874,5.52047022 31.5480159,8.095767 C34.2336758,10.4497821 31.5480159,14.4981824 25.9425788,16.9259581 C19.9859657,19.2820806 13.3270008,19.3537337 10.5242822,16.9259581 C8.07273959,14.4981824 10.7583995,10.5973032 16.4808953,8.16952754 L16.4808953,8.16952754 Z"
                id="Fill-71"
                fill="#000000"
            ></path>
            <path
                d="M16.4808953,8.16952754 C22.3237942,5.52047022 29.2101874,5.52047022 31.5480159,8.095767 C34.2336758,10.4497821 31.5480159,14.4981824 25.9425788,16.9259581 C19.9859657,19.2820806 13.3270008,19.3537337 10.5242822,16.9259581 C8.07273959,14.4981824 10.7583995,10.5973032 16.4808953,8.16952754 L16.4808953,8.16952754 Z"
                id="Stroke-72"
            ></path>
            <path
                d="M1.88200922,10.3760216 C3.40042715,9.71428412 5.85531429,10.2285005 7.13961491,11.3328012 C8.54097419,12.5108625 7.95902547,13.908098 6.43726301,14.7911171 C4.80178643,15.5287226 2.11612652,15.0123988 0.83182589,13.908098 C-0.218357436,12.7321442 -0.101298785,11.1852801 1.88200922,10.3760216 L1.88200922,10.3760216 Z"
                id="Fill-73"
                fill="#000000"
            ></path>
            <path
                d="M1.88200922,10.3760216 C3.40042715,9.71428412 5.85531429,10.2285005 7.13961491,11.3328012 C8.54097419,12.5108625 7.95902547,13.908098 6.43726301,14.7911171 C4.80178643,15.5287226 2.11612652,15.0123988 0.83182589,13.908098 C-0.218357436,12.7321442 -0.101298785,11.1852801 1.88200922,10.3760216 L1.88200922,10.3760216 Z"
                id="Stroke-74"
            ></path>
            <path
                d="M6.55432166,4.26864839 C8.30685689,3.68067146 10.5242822,3.89984566 11.9256415,5.15166749 C13.096228,6.25596824 12.8621107,7.80072481 10.9925168,8.68374392 C9.35704022,9.27382829 6.90549761,8.83126501 5.62119698,7.72696427 C4.33689636,6.40348933 4.80178643,4.85662531 6.55432166,4.26864839 L6.55432166,4.26864839 Z"
                id="Fill-75"
                fill="#000000"
            ></path>
            <path
                d="M6.55432166,4.26864839 C8.30685689,3.68067146 10.5242822,3.89984566 11.9256415,5.15166749 C13.096228,6.25596824 12.8621107,7.80072481 10.9925168,8.68374392 C9.35704022,9.27382829 6.90549761,8.83126501 5.62119698,7.72696427 C4.33689636,6.40348933 4.80178643,4.85662531 6.55432166,4.26864839 L6.55432166,4.26864839 Z"
                id="Stroke-76"
            ></path>
            <path
                d="M13.912294,0.441529777 C15.5477706,-0.146447146 17.9993132,0.294008685 19.1665552,1.25078834 C20.4508558,2.57637072 20.220083,4.1211273 18.4675478,4.85662531 C16.7150126,5.44670968 14.4975873,5.22542804 13.2132866,3.9736062 C11.9256415,2.65013127 12.1631033,1.17702779 13.912294,0.441529777 L13.912294,0.441529777 Z"
                id="Fill-77"
                fill="#000000"
            ></path>
            <path
                d="M13.912294,0.441529777 C15.5477706,-0.146447146 17.9993132,0.294008685 19.1665552,1.25078834 C20.4508558,2.57637072 20.220083,4.1211273 18.4675478,4.85662531 C16.7150126,5.44670968 14.4975873,5.22542804 13.2132866,3.9736062 C11.9256415,2.65013127 12.1631033,1.17702779 13.912294,0.441529777 L13.912294,0.441529777 Z"
                id="Stroke-78"
            ></path>
            <path
                d="M24.4241609,0.148595037 C26.0596375,-0.808184615 28.3941214,-0.293968238 29.678422,0.957853598 C31.19684,2.20756799 30.845664,3.53315037 29.2101874,4.41616948 C27.1098208,5.15166749 24.8890509,4.63745112 23.4876917,3.53315037 C22.2067356,2.28132854 22.6716256,0.589050868 24.4241609,0.148595037 L24.4241609,0.148595037 Z"
                id="Fill-79"
                fill="#000000"
            ></path>
            <path
                d="M24.4241609,0.148595037 C26.0596375,-0.808184615 28.3941214,-0.293968238 29.678422,0.957853598 C31.19684,2.20756799 30.845664,3.53315037 29.2101874,4.41616948 C27.1098208,5.15166749 24.8890509,4.63745112 23.4876917,3.53315037 C22.2067356,2.28132854 22.6716256,0.589050868 24.4241609,0.148595037 L24.4241609,0.148595037 Z"
                id="Stroke-80"
            ></path>
        </g>
        <g id="7" opacity="0" transform="translate(198.000000, 164.000000)">
            <path
                d="M16.313408,8.59176154 C22.0359037,6.23563896 29.0427001,6.23563896 31.4975873,8.51800099 C33.9491299,10.8720161 31.4975873,14.9941769 25.6580328,17.2744315 C19.7014198,19.6284467 12.9287407,19.8497283 10.5909122,17.2744315 C7.90525228,14.9941769 10.7079708,11.1670583 16.313408,8.59176154 L16.313408,8.59176154 Z"
                id="Fill-21"
                fill="#000000"
            ></path>
            <path
                d="M16.313408,8.59176154 C22.0359037,6.23563896 29.0427001,6.23563896 31.4975873,8.51800099 C33.9491299,10.8720161 31.4975873,14.9941769 25.6580328,17.2744315 C19.7014198,19.6284467 12.9287407,19.8497283 10.5909122,17.2744315 C7.90525228,14.9941769 10.7079708,11.1670583 16.313408,8.59176154 L16.313408,8.59176154 Z"
                id="Stroke-22"
            ></path>
            <path
                d="M1.48040461,10.8720161 C3.23293984,10.2102787 5.68448245,10.6507345 7.08584173,11.8287958 C8.13936959,13.0068571 7.78819363,14.4778531 6.15271705,15.3608722 C4.63429912,16.0226097 2.18275651,15.5821538 0.664338584,14.4040926 C-0.619962044,13.2281387 -0.15172744,11.5337536 1.48040461,10.8720161 L1.48040461,10.8720161 Z"
                id="Fill-23"
                fill="#000000"
            ></path>
            <path
                d="M1.48040461,10.8720161 C3.23293984,10.2102787 5.68448245,10.6507345 7.08584173,11.8287958 C8.13936959,13.0068571 7.78819363,14.4778531 6.15271705,15.3608722 C4.63429912,16.0226097 2.18275651,15.5821538 0.664338584,14.4040926 C-0.619962044,13.2281387 -0.15172744,11.5337536 1.48040461,10.8720161 L1.48040461,10.8720161 Z"
                id="Stroke-24"
            ></path>
            <path
                d="M6.503893,4.83840347 C8.13936959,4.10079801 10.4738535,4.3958402 11.6444401,5.64766203 C13.0457993,6.97113697 12.6946234,8.3704799 11.0591468,9.10597792 C9.30661156,9.91523648 6.73801031,9.4010201 5.57076833,8.07543772 C4.40018182,7.04489752 4.63429912,5.50014094 6.503893,4.83840347 L6.503893,4.83840347 Z"
                id="Fill-25"
                fill="#000000"
            ></path>
            <path
                d="M6.503893,4.83840347 C8.13936959,4.10079801 10.4738535,4.3958402 11.6444401,5.64766203 C13.0457993,6.97113697 12.6946234,8.3704799 11.0591468,9.10597792 C9.30661156,9.91523648 6.73801031,9.4010201 5.57076833,8.07543772 C4.40018182,7.04489752 4.63429912,5.50014094 6.503893,4.83840347 L6.503893,4.83840347 Z"
                id="Stroke-26"
            ></path>
            <path
                d="M13.8618654,0.937524318 C15.6144006,0.128265757 17.8318259,0.642482134 19.1161265,1.96806452 C20.6345444,3.07236526 20.1663098,4.61712184 18.5341778,5.27885931 C16.7816426,6.01435732 14.3267554,5.72142258 12.9287407,4.3958402 C11.6444401,3.14612581 11.9922715,1.67302233 13.8618654,0.937524318 L13.8618654,0.937524318 Z"
                id="Fill-27"
                fill="#000000"
            ></path>
            <path
                d="M13.8618654,0.937524318 C15.6144006,0.128265757 17.8318259,0.642482134 19.1161265,1.96806452 C20.6345444,3.07236526 20.1663098,4.61712184 18.5341778,5.27885931 C16.7816426,6.01435732 14.3267554,5.72142258 12.9287407,4.3958402 C11.6444401,3.14612581 11.9922715,1.67302233 13.8618654,0.937524318 L13.8618654,0.937524318 Z"
                id="Stroke-28"
            ></path>
            <path
                d="M24.1396149,0.349547395 C25.8921501,-0.240536973 28.3436928,0.128265757 29.5109347,1.3042196 C30.6781767,2.40852035 30.5611181,4.17455856 28.9256415,4.91005658 C27.1764508,5.72142258 24.6078495,5.13133821 23.5543217,4.10079801 C22.0359037,2.77732308 22.5041383,1.15880596 24.1396149,0.349547395 L24.1396149,0.349547395 Z"
                id="Fill-29"
                fill="#000000"
            ></path>
            <path
                d="M24.1396149,0.349547395 C25.8921501,-0.240536973 28.3436928,0.128265757 29.5109347,1.3042196 C30.6781767,2.40852035 30.5611181,4.17455856 28.9256415,4.91005658 C27.1764508,5.72142258 24.6078495,5.13133821 23.5543217,4.10079801 C22.0359037,2.77732308 22.5041383,1.15880596 24.1396149,0.349547395 L24.1396149,0.349547395 Z"
                id="Stroke-30"
            ></path>
        </g>
        <g id="8" opacity="0" transform="translate(145.000000, 166.000000)">
            <path
                d="M16.8744768,8.4315603 C22.5969726,5.85415608 29.6071135,5.85415608 31.9415975,8.21027866 C34.5101987,10.6380543 31.8245388,14.5389335 26.2191017,17.1142303 C20.2624886,19.3944849 13.7239269,19.542006 11.1519811,17.1142303 C8.34926251,14.6864546 11.2690397,10.859336 16.8744768,8.4315603 L16.8744768,8.4315603 Z"
                id="Fill-31"
                fill="#000000"
            ></path>
            <path
                d="M16.8744768,8.4315603 C22.5969726,5.85415608 29.6071135,5.85415608 31.9415975,8.21027866 C34.5101987,10.6380543 31.8245388,14.5389335 26.2191017,17.1142303 C20.2624886,19.3944849 13.7239269,19.542006 11.1519811,17.1142303 C8.34926251,14.6864546 11.2690397,10.859336 16.8744768,8.4315603 L16.8744768,8.4315603 Z"
                id="Stroke-32"
            ></path>
            <path
                d="M2.16187667,10.7118149 C3.79735325,9.97631687 6.24889585,10.2713591 7.53319648,11.5210734 C8.70043846,12.7728953 8.34926251,14.3176519 6.71713046,15.0531499 C4.96459522,15.7169948 2.62676674,15.2744315 1.22540746,14.0963702 C-0.0588931713,12.9941769 0.409341433,11.4473129 2.16187667,10.7118149 L2.16187667,10.7118149 Z"
                id="Fill-33"
                fill="#000000"
            ></path>
            <path
                d="M2.16187667,10.7118149 C3.79735325,9.97631687 6.24889585,10.2713591 7.53319648,11.5210734 C8.70043846,12.7728953 8.34926251,14.3176519 6.71713046,15.0531499 C4.96459522,15.7169948 2.62676674,15.2744315 1.22540746,14.0963702 C-0.0588931713,12.9941769 0.409341433,11.4473129 2.16187667,10.7118149 L2.16187667,10.7118149 Z"
                id="Stroke-34"
            ></path>
            <path
                d="M6.71713046,4.4569206 C8.70043846,3.79518313 10.687091,3.94059677 12.3225676,5.26617916 C13.4898096,6.51800099 13.2556922,8.06275757 11.6202157,8.79825558 C9.86768044,9.5337536 7.29907918,9.16705831 6.1318372,7.91523648 C4.84753657,6.59176154 5.19871253,5.19241861 6.71713046,4.4569206 L6.71713046,4.4569206 Z"
                id="Fill-35"
                fill="#000000"
            ></path>
            <path
                d="M6.71713046,4.4569206 C8.70043846,3.79518313 10.687091,3.94059677 12.3225676,5.26617916 C13.4898096,6.51800099 13.2556922,8.06275757 11.6202157,8.79825558 C9.86768044,9.5337536 7.29907918,9.16705831 6.1318372,7.91523648 C4.84753657,6.59176154 5.19871253,5.19241861 6.71713046,4.4569206 L6.71713046,4.4569206 Z"
                id="Stroke-36"
            ></path>
            <path
                d="M14.0717583,0.851083623 C16.0584108,0.041825062 18.3928948,0.334759801 19.6805399,1.66034218 C20.9648406,2.91005658 20.6136646,4.38316005 18.978188,5.04489752 C17.1085941,5.85415608 14.8911688,5.3399397 13.4898096,4.01435732 C12.0884503,2.98381712 12.4396262,1.43906055 14.0717583,0.851083623 L14.0717583,0.851083623 Z"
                id="Fill-37"
                fill="#000000"
            ></path>
            <path
                d="M14.0717583,0.851083623 C16.0584108,0.041825062 18.3928948,0.334759801 19.6805399,1.66034218 C20.9648406,2.91005658 20.6136646,4.38316005 18.978188,5.04489752 C17.1085941,5.85415608 14.8911688,5.3399397 13.4898096,4.01435732 C12.0884503,2.98381712 12.4396262,1.43906055 14.0717583,0.851083623 L14.0717583,0.851083623 Z"
                id="Stroke-38"
            ></path>
            <path
                d="M24.7006838,0.189346154 C26.453219,-0.474498759 28.6706443,-0.0319354839 29.9582895,1.07236526 C31.4767074,2.32207965 31.0084728,3.79518313 29.4900549,4.53068114 C27.620461,5.26617916 25.1689184,4.97113697 23.8846178,3.79518313 C22.8310899,2.46960074 23.0652072,0.924844169 24.7006838,0.189346154 L24.7006838,0.189346154 Z"
                id="Fill-39"
                fill="#000000"
            ></path>
            <path
                d="M24.7006838,0.189346154 C26.453219,-0.474498759 28.6706443,-0.0319354839 29.9582895,1.07236526 C31.4767074,2.32207965 31.0084728,3.79518313 29.4900549,4.53068114 C27.620461,5.26617916 25.1689184,4.97113697 23.8846178,3.79518313 C22.8310899,2.46960074 23.0652072,0.924844169 24.7006838,0.189346154 L24.7006838,0.189346154 Z"
                id="Stroke-40"
            ></path>
        </g>
        <g id="9" opacity="0" transform="translate(142.000000, 127.000000)">
            <path
                d="M17.8911688,7.69148561 C24.4297306,6.14462159 30.8545783,7.10350868 32.4900549,9.82632655 C34.1255315,12.4753839 29.9214536,16.0812208 23.4966059,17.5522169 C16.8409855,18.9515598 10.2990792,18.1423012 8.8977199,15.2719623 C7.26224332,12.5491444 11.4663212,9.16248164 17.8911688,7.69148561 L17.8911688,7.69148561 Z"
                id="Fill-81"
                fill="#000000"
            ></path>
            <path
                d="M17.8911688,7.69148561 C24.4297306,6.14462159 30.8545783,7.10350868 32.4900549,9.82632655 C34.1255315,12.4753839 29.9214536,16.0812208 23.4966059,17.5522169 C16.8409855,18.9515598 10.2990792,18.1423012 8.8977199,15.2719623 C7.26224332,12.5491444 11.4663212,9.16248164 17.8911688,7.69148561 L17.8911688,7.69148561 Z"
                id="Stroke-82"
            ></path>
            <path
                d="M2.59327541,7.76524615 C4.57658341,7.32479032 6.79735325,8.13404888 7.49636062,9.23624218 C8.31577118,10.6355851 7.26224332,12.2541022 5.51305262,12.5491444 C3.64345873,13.0633608 1.4226889,12.3278628 0.372505572,11.0043878 C-0.326501801,9.60504491 0.606622874,8.20780943 2.59327541,7.76524615 L2.59327541,7.76524615 Z"
                id="Fill-83"
                fill="#000000"
            ></path>
            <path
                d="M2.59327541,7.76524615 C4.57658341,7.32479032 6.79735325,8.13404888 7.49636062,9.23624218 C8.31577118,10.6355851 7.26224332,12.2541022 5.51305262,12.5491444 C3.64345873,13.0633608 1.4226889,12.3278628 0.372505572,11.0043878 C-0.326501801,9.60504491 0.606622874,8.20780943 2.59327541,7.76524615 L2.59327541,7.76524615 Z"
                id="Stroke-84"
            ></path>
            <path
                d="M9.60007181,2.39337097 C11.4663212,1.95291514 13.687091,2.61465261 14.3860984,4.01188809 C15.2055089,5.33747047 14.3860984,6.8084665 12.519849,7.32479032 C10.2990792,7.76524615 8.31577118,7.02974814 7.49636062,5.63251266 C6.6802946,4.15940918 7.61341927,2.7621737 9.60007181,2.39337097 L9.60007181,2.39337097 Z"
                id="Fill-85"
                fill="#000000"
            ></path>
            <path
                d="M9.60007181,2.39337097 C11.4663212,1.95291514 13.687091,2.61465261 14.3860984,4.01188809 C15.2055089,5.33747047 14.3860984,6.8084665 12.519849,7.32479032 C10.2990792,7.76524615 8.31577118,7.02974814 7.49636062,5.63251266 C6.6802946,4.15940918 7.61341927,2.7621737 9.60007181,2.39337097 L9.60007181,2.39337097 Z"
                id="Stroke-86"
            ></path>
            <path
                d="M18.0082275,-0.108165261 C20.1085941,-0.403207444 22.2123053,0.258530025 23.14543,1.58411241 C23.9648406,2.90969479 22.7975986,4.38069082 21.0450633,4.82114665 C19.0584108,5.26370993 16.8409855,4.38069082 16.0215749,3.12886898 C15.2055089,1.7316335 16.3727509,0.332290571 18.0082275,-0.108165261 L18.0082275,-0.108165261 Z"
                id="Fill-87"
                fill="#000000"
            ></path>
            <path
                d="M18.0082275,-0.108165261 C20.1085941,-0.403207444 22.2123053,0.258530025 23.14543,1.58411241 C23.9648406,2.90969479 22.7975986,4.38069082 21.0450633,4.82114665 C19.0584108,5.26370993 16.8409855,4.38069082 16.0215749,3.12886898 C15.2055089,1.7316335 16.3727509,0.332290571 18.0082275,-0.108165261 L18.0082275,-0.108165261 Z"
                id="Stroke-88"
            ></path>
            <path
                d="M28.4030357,0.996135484 C30.620461,0.479811663 32.6071135,1.14154913 33.6572969,2.61465261 C34.1255315,4.01188809 33.3061209,5.41123102 31.436527,5.92544739 C29.3361604,6.29425012 27.2357937,5.63251266 26.2993245,4.30693027 C25.4832585,2.90969479 26.5334418,1.28907022 28.4030357,0.996135484 L28.4030357,0.996135484 Z"
                id="Fill-89"
                fill="#000000"
            ></path>
            <path
                d="M28.4030357,0.996135484 C30.620461,0.479811663 32.6071135,1.14154913 33.6572969,2.61465261 C34.1255315,4.01188809 33.3061209,5.41123102 31.436527,5.92544739 C29.3361604,6.29425012 27.2357937,5.63251266 26.2993245,4.30693027 C25.4832585,2.90969479 26.5334418,1.28907022 28.4030357,0.996135484 L28.4030357,0.996135484 Z"
                id="Stroke-90"
            ></path>
        </g>
        <g id="10" opacity="0" transform="translate(91.000000, 121.000000)">
            <path
                d="M17.2716483,7.51035186 C23.8102101,6.03724839 30.3521164,6.99613548 31.636417,9.6451928 C33.5026664,12.3680107 29.2985885,15.8263266 22.8770854,17.2973226 C16.4522377,18.9896002 9.79661726,17.8136464 8.27819933,15.0908285 C6.7597814,12.3680107 10.6126833,8.98134789 17.2716483,7.51035186 L17.2716483,7.51035186 Z"
                id="Fill-91"
                fill="#000000"
            ></path>
            <path
                d="M17.2716483,7.51035186 C23.8102101,6.03724839 30.3521164,6.99613548 31.636417,9.6451928 C33.5026664,12.3680107 29.2985885,15.8263266 22.8770854,17.2973226 C16.4522377,18.9896002 9.79661726,17.8136464 8.27819933,15.0908285 C6.7597814,12.3680107 10.6126833,8.98134789 17.2716483,7.51035186 L17.2716483,7.51035186 Z"
                id="Stroke-92"
            ></path>
            <path
                d="M1.97041031,7.58411241 C3.84000419,7.06989603 5.94037084,7.87915459 6.87684005,9.27639007 C7.57584742,10.6736256 6.7597814,11.9992079 4.89018752,12.3680107 C2.67276221,12.882227 0.569051026,12.1446216 -0.0128976959,10.8211467 C-1.06308102,9.35015062 0.104160955,7.95291514 1.97041031,7.58411241 L1.97041031,7.58411241 Z"
                id="Fill-93"
                fill="#000000"
            ></path>
            <path
                d="M1.97041031,7.58411241 C3.84000419,7.06989603 5.94037084,7.87915459 6.87684005,9.27639007 C7.57584742,10.6736256 6.7597814,11.9992079 4.89018752,12.3680107 C2.67276221,12.882227 0.569051026,12.1446216 -0.0128976959,10.8211467 C-1.06308102,9.35015062 0.104160955,7.95291514 1.97041031,7.58411241 L1.97041031,7.58411241 Z"
                id="Stroke-94"
            ></path>
            <path
                d="M8.86014805,2.21223722 C10.8468006,1.76967395 13.0675704,2.5072794 13.7665778,3.90451489 C14.5859884,5.30175037 13.7665778,6.62733275 11.7799253,7.06989603 C9.91367591,7.58411241 7.57584742,6.84861439 6.9938987,5.59679256 C5.94037084,4.19955707 6.9938987,2.65269305 8.86014805,2.21223722 L8.86014805,2.21223722 Z"
                id="Fill-95"
                fill="#000000"
            ></path>
            <path
                d="M8.86014805,2.21223722 C10.8468006,1.76967395 13.0675704,2.5072794 13.7665778,3.90451489 C14.5859884,5.30175037 13.7665778,6.62733275 11.7799253,7.06989603 C9.91367591,7.58411241 7.57584742,6.84861439 6.9938987,5.59679256 C5.94037084,4.19955707 6.9938987,2.65269305 8.86014805,2.21223722 L8.86014805,2.21223722 Z"
                id="Stroke-96"
            ></path>
            <path
                d="M17.2716483,-0.289299007 C19.6061322,-0.658101737 21.5927848,0.00363573201 22.5259094,1.40087122 C23.34532,2.80021414 22.1747335,4.12579653 20.5392569,4.6400129 C18.4388902,5.08257618 16.2214649,4.27331762 15.519113,2.87397469 C14.5859884,1.55049975 15.519113,0.151156824 17.2716483,-0.289299007 L17.2716483,-0.289299007 Z"
                id="Fill-97"
                fill="#000000"
            ></path>
            <path
                d="M17.2716483,-0.289299007 C19.6061322,-0.658101737 21.5927848,0.00363573201 22.5259094,1.40087122 C23.34532,2.80021414 22.1747335,4.12579653 20.5392569,4.6400129 C18.4388902,5.08257618 16.2214649,4.27331762 15.519113,2.87397469 C14.5859884,1.55049975 15.519113,0.151156824 17.2716483,-0.289299007 L17.2716483,-0.289299007 Z"
                id="Stroke-98"
            ></path>
            <path
                d="M27.8972292,0.739133747 C29.6497645,0.372438462 32.1013071,1.03417593 32.803659,2.5072794 C33.5026664,3.7569938 32.5695417,5.15633672 30.8170065,5.74431365 C28.7166398,6.11311638 26.6129286,5.59679256 25.7968626,4.12579653 C24.7466793,2.65269305 25.9139212,1.10793648 27.8972292,0.739133747 L27.8972292,0.739133747 Z"
                id="Fill-99"
                fill="#000000"
            ></path>
            <path
                d="M27.8972292,0.739133747 C29.6497645,0.372438462 32.1013071,1.03417593 32.803659,2.5072794 C33.5026664,3.7569938 32.5695417,5.15633672 30.8170065,5.74431365 C28.7166398,6.11311638 26.6129286,5.59679256 25.7968626,4.12579653 C24.7466793,2.65269305 25.9139212,1.10793648 27.8972292,0.739133747 L27.8972292,0.739133747 Z"
                id="Stroke-100"
            ></path>
        </g>
        <g id="11" opacity="0" transform="translate(97.000000, 66.000000)">
            <path
                d="M16.8770854,8.71573201 C22.4825225,6.21419578 29.3722602,6.14043524 31.9408615,8.71573201 C34.27869,10.998094 31.8271474,14.8989732 25.8705343,17.4005094 C20.0309799,19.8282851 13.4890736,19.8282851 10.8034137,17.4005094 C8.35187105,15.1181474 11.037531,11.1456151 16.8770854,8.71573201 L16.8770854,8.71573201 Z"
                id="Fill-101"
                fill="#000000"
            ></path>
            <path
                d="M16.8770854,8.71573201 C22.4825225,6.21419578 29.3722602,6.14043524 31.9408615,8.71573201 C34.27869,10.998094 31.8271474,14.8989732 25.8705343,17.4005094 C20.0309799,19.8282851 13.4890736,19.8282851 10.8034137,17.4005094 C8.35187105,15.1181474 11.037531,11.1456151 16.8770854,8.71573201 L16.8770854,8.71573201 Z"
                id="Stroke-102"
            ></path>
            <path
                d="M2.04408203,10.998094 C3.56249996,10.262596 6.13110121,10.6292913 7.41540184,11.8073526 C8.58598835,13.1308275 8.35187105,14.603931 6.48227717,15.339429 C4.84680059,16.0011665 2.27819933,15.6344712 1.11095735,14.5301705 C-0.290401927,13.4258697 0.174488144,11.7335921 2.04408203,10.998094 L2.04408203,10.998094 Z"
                id="Fill-103"
                fill="#000000"
            ></path>
            <path
                d="M2.04408203,10.998094 C3.56249996,10.262596 6.13110121,10.6292913 7.41540184,11.8073526 C8.58598835,13.1308275 8.35187105,14.603931 6.48227717,15.339429 C4.84680059,16.0011665 2.27819933,15.6344712 1.11095735,14.5301705 C-0.290401927,13.4258697 0.174488144,11.7335921 2.04408203,10.998094 L2.04408203,10.998094 Z"
                id="Stroke-104"
            ></path>
            <path
                d="M6.71639447,4.8169603 C8.4689297,4.00559429 10.686355,4.44815757 12.2047729,5.84539305 C13.3720149,6.87593325 13.0241835,8.34903672 11.6228242,9.08453474 C9.51911303,10.0413144 7.29834319,9.52499057 5.66621114,8.20151563 C4.49896917,7.17097543 4.84680059,5.40493722 6.71639447,4.8169603 L6.71639447,4.8169603 Z"
                id="Fill-105"
                fill="#000000"
            ></path>
            <path
                d="M6.71639447,4.8169603 C8.4689297,4.00559429 10.686355,4.44815757 12.2047729,5.84539305 C13.3720149,6.87593325 13.0241835,8.34903672 11.6228242,9.08453474 C9.51911303,10.0413144 7.29834319,9.52499057 5.66621114,8.20151563 C4.49896917,7.17097543 4.84680059,5.40493722 6.71639447,4.8169603 L6.71639447,4.8169603 Z"
                id="Stroke-106"
            ></path>
            <path
                d="M14.0743668,1.06360223 C15.7098434,0.328104218 18.3955033,0.842320596 19.5627453,1.87286079 C20.7299873,3.12257519 20.49587,4.59567866 18.7466793,5.40493722 C16.994144,6.14043524 14.4255428,5.84539305 13.3720149,4.52191811 C11.9706556,3.27009628 12.4388902,1.79910025 14.0743668,1.06360223 L14.0743668,1.06360223 Z"
                id="Fill-107"
                fill="#000000"
            ></path>
            <path
                d="M14.0743668,1.06360223 C15.7098434,0.328104218 18.3955033,0.842320596 19.5627453,1.87286079 C20.7299873,3.12257519 20.49587,4.59567866 18.7466793,5.40493722 C16.994144,6.14043524 14.4255428,5.84539305 13.3720149,4.52191811 C11.9706556,3.27009628 12.4388902,1.79910025 14.0743668,1.06360223 L14.0743668,1.06360223 Z"
                id="Stroke-108"
            ></path>
            <path
                d="M24.469175,0.473517866 C26.452483,-0.188219603 28.5561942,0.254343672 29.9575535,1.28277643 C31.2418541,2.60835881 30.8906782,4.30063648 28.9040256,5.03613449 C27.3889522,5.84539305 25.0511238,5.33117667 23.8838818,4.00559429 C22.2484052,2.97716154 22.7166398,1.21112333 24.469175,0.473517866 L24.469175,0.473517866 Z"
                id="Fill-109"
                fill="#000000"
            ></path>
            <path
                d="M24.469175,0.473517866 C26.452483,-0.188219603 28.5561942,0.254343672 29.9575535,1.28277643 C31.2418541,2.60835881 30.8906782,4.30063648 28.9040256,5.03613449 C27.3889522,5.84539305 25.0511238,5.33117667 23.8838818,4.00559429 C22.2484052,2.97716154 22.7166398,1.21112333 24.469175,0.473517866 L24.469175,0.473517866 Z"
                id="Stroke-110"
            ></path>
        </g>
        <g id="12" opacity="0" transform="translate(45.000000, 68.000000)">
            <path
                d="M16.3210956,8.33635658 C22.0435914,6.05399454 28.9366736,5.980234 31.3882163,8.33635658 C33.8397589,10.6903717 31.3882163,14.6650114 25.4316032,17.0927871 C19.5920488,19.5943233 13.0501424,19.5943233 10.2507684,17.0927871 C8.02999858,14.8104251 10.3644825,10.7641323 16.3210956,8.33635658 L16.3210956,8.33635658 Z"
                id="Fill-111"
                fill="#000000"
            ></path>
            <path
                d="M16.3210956,8.33635658 C22.0435914,6.05399454 28.9366736,5.980234 31.3882163,8.33635658 C33.8397589,10.6903717 31.3882163,14.6650114 25.4316032,17.0927871 C19.5920488,19.5943233 13.0501424,19.5943233 10.2507684,17.0927871 C8.02999858,14.8104251 10.3644825,10.7641323 16.3210956,8.33635658 L16.3210956,8.33635658 Z"
                id="Stroke-112"
            ></path>
            <path
                d="M1.6051509,10.6903717 C3.12691336,10.0265268 5.57845597,10.3953295 6.8627566,11.6471514 C8.14705723,12.8252127 7.67882262,14.3699692 6.04334604,15.0317067 C4.29415534,15.7672047 1.8392682,15.4005094 0.554967574,14.2962087 C-0.729333054,13.1181474 -0.378157101,11.4258697 1.6051509,10.6903717 L1.6051509,10.6903717 Z"
                id="Fill-113"
                fill="#000000"
            ></path>
            <path
                d="M1.6051509,10.6903717 C3.12691336,10.0265268 5.57845597,10.3953295 6.8627566,11.6471514 C8.14705723,12.8252127 7.67882262,14.3699692 6.04334604,15.0317067 C4.29415534,15.7672047 1.8392682,15.4005094 0.554967574,14.2962087 C-0.729333054,13.1181474 -0.378157101,11.4258697 1.6051509,10.6903717 L1.6051509,10.6903717 Z"
                id="Stroke-114"
            ></path>
            <path
                d="M6.16040469,4.58299851 C7.79588127,3.92126104 10.3644825,4.14043524 11.535069,5.39014963 C12.9364283,6.64197146 12.5852524,8.18883548 10.8327171,8.77681241 C9.0801819,9.65983151 6.74569795,9.29102878 5.46139732,8.04131439 C3.94297939,6.78949256 4.41121399,5.24473598 6.16040469,4.58299851 L6.16040469,4.58299851 Z"
                id="Fill-115"
                fill="#000000"
            ></path>
            <path
                d="M6.16040469,4.58299851 C7.79588127,3.92126104 10.3644825,4.14043524 11.535069,5.39014963 C12.9364283,6.64197146 12.5852524,8.18883548 10.8327171,8.77681241 C9.0801819,9.65983151 6.74569795,9.29102878 5.46139732,8.04131439 C3.94297939,6.78949256 4.41121399,5.24473598 6.16040469,4.58299851 L6.16040469,4.58299851 Z"
                id="Stroke-116"
            ></path>
            <path
                d="M13.6354357,0.755879901 C15.2709123,-0.05337866 17.9565722,0.387077171 19.0101,1.7864201 C20.2944007,2.8169603 20.0602834,4.43547742 18.1906895,5.24473598 C16.4381543,5.980234 13.9866116,5.46601762 12.9364283,4.21419578 C11.535069,3.03613449 11.8829005,1.34385682 13.6354357,0.755879901 L13.6354357,0.755879901 Z"
                id="Fill-117"
                fill="#000000"
            ></path>
            <path
                d="M13.6354357,0.755879901 C15.2709123,-0.05337866 17.9565722,0.387077171 19.0101,1.7864201 C20.2944007,2.8169603 20.0602834,4.43547742 18.1906895,5.24473598 C16.4381543,5.980234 13.9866116,5.46601762 12.9364283,4.21419578 C11.535069,3.03613449 11.8829005,1.34385682 13.6354357,0.755879901 L13.6354357,0.755879901 Z"
                id="Stroke-118"
            ></path>
            <path
                d="M23.9131853,0.239556079 C25.7827791,-0.42218139 28.1172631,-0.127139206 29.5186224,1.12257519 C30.6858643,2.37439702 30.3380329,3.92126104 28.5854977,4.58299851 C26.9500211,5.39014963 24.7292513,5.17097543 23.2108333,3.84539305 C21.8128186,2.59567866 22.2777087,1.04881464 23.9131853,0.239556079 L23.9131853,0.239556079 Z"
                id="Fill-119"
                fill="#000000"
            ></path>
            <path
                d="M23.9131853,0.239556079 C25.7827791,-0.42218139 28.1172631,-0.127139206 29.5186224,1.12257519 C30.6858643,2.37439702 30.3380329,3.92126104 28.5854977,4.58299851 C26.9500211,5.39014963 24.7292513,5.17097543 23.2108333,3.84539305 C21.8128186,2.59567866 22.2777087,1.04881464 23.9131853,0.239556079 L23.9131853,0.239556079 Z"
                id="Stroke-120"
            ></path>
        </g>
        <g id="13" opacity="0" transform="translate(50.000000, 9.000000)">
            <path
                d="M18.2108333,7.43225608 C24.7527397,6.25630223 31.4083601,7.43225608 32.8097194,10.2288345 C34.2110787,12.9516524 29.6591694,16.2645546 23.0002044,17.4405084 C16.2275253,18.6185697 9.80267767,17.4405084 8.51837704,14.64393 C7.23407641,11.8494591 11.6722716,8.68407792 18.2108333,7.43225608 L18.2108333,7.43225608 Z"
                id="Fill-121"
                fill="#000000"
            ></path>
            <path
                d="M18.2108333,7.43225608 C24.7527397,6.25630223 31.4083601,7.43225608 32.8097194,10.2288345 C34.2110787,12.9516524 29.6591694,16.2645546 23.0002044,17.4405084 C16.2275253,18.6185697 9.80267767,17.4405084 8.51837704,14.64393 C7.23407641,11.8494591 11.6722716,8.68407792 18.2108333,7.43225608 L18.2108333,7.43225608 Z"
                id="Stroke-122"
            ></path>
            <path
                d="M3.02999858,6.84427916 C5.01665111,6.47758387 6.99995911,7.28684243 7.58525237,8.68407792 C8.51837704,10.0096603 7.23407641,11.4806563 5.36448253,11.7756985 C3.38117453,12.1423938 1.39452199,11.4806563 0.57845597,10.0096603 C-0.00683728529,8.53655682 1.04334604,7.28684243 3.02999858,6.84427916 L3.02999858,6.84427916 Z"
                id="Fill-123"
                fill="#000000"
            ></path>
            <path
                d="M3.02999858,6.84427916 C5.01665111,6.47758387 6.99995911,7.28684243 7.58525237,8.68407792 C8.51837704,10.0096603 7.23407641,11.4806563 5.36448253,11.7756985 C3.38117453,12.1423938 1.39452199,11.4806563 0.57845597,10.0096603 C-0.00683728529,8.53655682 1.04334604,7.28684243 3.02999858,6.84427916 L3.02999858,6.84427916 Z"
                id="Stroke-124"
            ></path>
            <path
                d="M10.6220882,1.76744615 C12.4883376,1.47240397 14.4749901,2.28166253 15.177342,3.60724491 C15.8763494,5.0044804 14.8261661,6.33006278 13.0736308,6.84427916 C10.8562055,7.28684243 8.63543569,6.33006278 8.05014244,4.85695931 C7.46819372,3.60724491 8.63543569,1.98872779 10.6220882,1.76744615 L10.6220882,1.76744615 Z"
                id="Fill-125"
                fill="#000000"
            ></path>
            <path
                d="M10.6220882,1.76744615 C12.4883376,1.47240397 14.4749901,2.28166253 15.177342,3.60724491 C15.8763494,5.0044804 14.8261661,6.33006278 13.0736308,6.84427916 C10.8562055,7.28684243 8.63543569,6.33006278 8.05014244,4.85695931 C7.46819372,3.60724491 8.63543569,1.98872779 10.6220882,1.76744615 L10.6220882,1.76744615 Z"
                id="Stroke-126"
            ></path>
            <path
                d="M19.3814199,-0.367394789 C21.3647279,-0.514915881 23.468439,0.220582134 24.0503878,1.47240397 C24.635681,2.9434 23.468439,4.19522184 21.8329625,4.71154566 C19.8463099,5.0044804 17.511826,4.19522184 16.8128186,2.8717469 C16.4616426,1.32488288 17.2777087,0.0751684864 19.3814199,-0.367394789 L19.3814199,-0.367394789 Z"
                id="Fill-127"
                fill="#000000"
            ></path>
            <path
                d="M19.3814199,-0.367394789 C21.3647279,-0.514915881 23.468439,0.220582134 24.0503878,1.47240397 C24.635681,2.9434 23.468439,4.19522184 21.8329625,4.71154566 C19.8463099,5.0044804 17.511826,4.19522184 16.8128186,2.8717469 C16.4616426,1.32488288 17.2777087,0.0751684864 19.3814199,-0.367394789 L19.3814199,-0.367394789 Z"
                id="Stroke-128"
            ></path>
            <path
                d="M29.6591694,1.17736179 C31.5254188,0.736905955 33.62913,1.69368561 34.445196,2.9434 C34.7963719,4.41650347 34.2110787,5.73997841 31.9936534,6.10878114 C29.8899422,6.54923697 27.7895755,5.73997841 27.3213409,4.34274293 C26.5052749,2.9434 27.6725169,1.47240397 29.6591694,1.17736179 L29.6591694,1.17736179 Z"
                id="Fill-129"
                fill="#000000"
            ></path>
            <path
                d="M29.6591694,1.17736179 C31.5254188,0.736905955 33.62913,1.69368561 34.445196,2.9434 C34.7963719,4.41650347 34.2110787,5.73997841 31.9936534,6.10878114 C29.8899422,6.54923697 27.7895755,5.73997841 27.3213409,4.34274293 C26.5052749,2.9434 27.6725169,1.47240397 29.6591694,1.17736179 L29.6591694,1.17736179 Z"
                id="Stroke-130"
            ></path>
        </g>
        <g id="14" opacity="0">
            <path
                d="M17.527782,8.11838883 C24.0663438,6.71904591 30.7253088,8.11838883 31.8925507,10.9149672 C33.0597927,13.7115457 28.7386562,16.9485799 21.8489185,18.1982943 C15.1899535,19.3763556 9.00256766,18.0528806 7.48414973,15.3300628 C6.1998491,12.6072449 10.7517584,9.36810323 17.527782,8.11838883 L17.527782,8.11838883 Z"
                id="Fill-131"
                fill="#000000"
            ></path>
            <path
                d="M17.527782,8.11838883 C24.0663438,6.71904591 30.7253088,8.11838883 31.8925507,10.9149672 C33.0597927,13.7115457 28.7386562,16.9485799 21.8489185,18.1982943 C15.1899535,19.3763556 9.00256766,18.0528806 7.48414973,15.3300628 C6.1998491,12.6072449 10.7517584,9.36810323 17.527782,8.11838883 L17.527782,8.11838883 Z"
                id="Stroke-132"
            ></path>
            <path
                d="M2.10948538,7.45454392 C3.86202061,7.23536973 6.1998491,8.11838883 6.89885647,9.44186377 C7.48414973,10.6936856 6.31356322,12.2384422 4.44731387,12.4597238 C2.22654403,12.9001797 0.36029468,12.1646816 -0.224998576,10.6936856 C-0.810291831,9.36810323 0.243236028,7.8971072 2.10948538,7.45454392 L2.10948538,7.45454392 Z"
                id="Fill-133"
                fill="#000000"
            ></path>
            <path
                d="M2.10948538,7.45454392 C3.86202061,7.23536973 6.1998491,8.11838883 6.89885647,9.44186377 C7.48414973,10.6936856 6.31356322,12.2384422 4.44731387,12.4597238 C2.22654403,12.9001797 0.36029468,12.1646816 -0.224998576,10.6936856 C-0.810291831,9.36810323 0.243236028,7.8971072 2.10948538,7.45454392 L2.10948538,7.45454392 Z"
                id="Stroke-134"
            ></path>
            <path
                d="M9.58451638,2.45147146 C11.6882276,2.23018983 13.9056529,3.03944839 14.2568288,4.36503077 C14.9558362,5.76226625 13.9056529,7.16160918 11.9190003,7.45454392 C9.93569233,7.8971072 7.83198115,7.16160918 7.36709108,5.68850571 C6.54768052,4.36503077 7.83198115,2.89403474 9.58451638,2.45147146 L9.58451638,2.45147146 Z"
                id="Fill-135"
                fill="#000000"
            ></path>
            <path
                d="M9.58451638,2.45147146 C11.6882276,2.23018983 13.9056529,3.03944839 14.2568288,4.36503077 C14.9558362,5.76226625 13.9056529,7.16160918 11.9190003,7.45454392 C9.93569233,7.8971072 7.83198115,7.16160918 7.36709108,5.68850571 C6.54768052,4.36503077 7.83198115,2.89403474 9.58451638,2.45147146 L9.58451638,2.45147146 Z"
                id="Stroke-136"
            ></path>
            <path
                d="M18.4609067,0.316630521 C20.6816765,0.0215883375 22.6649845,0.832954342 23.2502778,2.23018983 C23.9492851,3.55577221 22.7820432,5.10052878 20.7953906,5.39557097 C18.8120826,5.76226625 16.5913128,4.95300769 16.1264227,3.55577221 C15.3070121,2.15642928 16.4775987,0.685433251 18.4609067,0.316630521 L18.4609067,0.316630521 Z"
                id="Fill-137"
                fill="#000000"
            ></path>
            <path
                d="M18.4609067,0.316630521 C20.6816765,0.0215883375 22.6649845,0.832954342 23.2502778,2.23018983 C23.9492851,3.55577221 22.7820432,5.10052878 20.7953906,5.39557097 C18.8120826,5.76226625 16.5913128,4.95300769 16.1264227,3.55577221 C15.3070121,2.15642928 16.4775987,0.685433251 18.4609067,0.316630521 L18.4609067,0.316630521 Z"
                id="Stroke-138"
            ></path>
            <path
                d="M28.7386562,1.71597345 C30.8423674,1.49469181 32.8256754,2.30395037 33.29391,3.70118586 C33.9929174,5.17428933 33.0597927,6.49776427 31.0731402,6.71904591 C28.9727735,7.23536973 26.7520037,6.49776427 26.2871136,5.10052878 C25.7018204,3.62953275 26.5178864,2.30395037 28.7386562,1.71597345 L28.7386562,1.71597345 Z"
                id="Fill-139"
                fill="#000000"
            ></path>
            <path
                d="M28.7386562,1.71597345 C30.8423674,1.49469181 32.8256754,2.30395037 33.29391,3.70118586 C33.9929174,5.17428933 33.0597927,6.49776427 31.0731402,6.71904591 C28.9727735,7.23536973 26.7520037,6.49776427 26.2871136,5.10052878 C25.7018204,3.62953275 26.5178864,2.30395037 28.7386562,1.71597345 L28.7386562,1.71597345 Z"
                id="Stroke-140"
            ></path>
        </g>
        <animate
            xlinkHref="#1"
            attributeType="CSS"
            attributeName="opacity"
            from="0"
            to="1"
            dur=".5s"
            begin="1s"
            fill="freeze"
        />
        <animate
            xlinkHref="#2"
            attributeType="CSS"
            attributeName="opacity"
            from="0"
            to="1"
            dur=".5s"
            begin="1.4s"
            fill="freeze"
        />
        <animate
            xlinkHref="#3"
            attributeType="CSS"
            attributeName="opacity"
            from="0"
            to="1"
            dur=".5s"
            begin="1.8s"
            fill="freeze"
        />
        <animate
            xlinkHref="#4"
            attributeType="CSS"
            attributeName="opacity"
            from="0"
            to="1"
            dur=".5s"
            begin="2.2s"
            fill="freeze"
        />
        <animate
            xlinkHref="#5"
            attributeType="CSS"
            attributeName="opacity"
            from="0"
            to="1"
            dur=".5s"
            begin="2.6s"
            fill="freeze"
        />
        <animate
            xlinkHref="#6"
            attributeType="CSS"
            attributeName="opacity"
            from="0"
            to="1"
            dur=".5s"
            begin="3s"
            fill="freeze"
        />
        <animate
            xlinkHref="#7"
            attributeType="CSS"
            attributeName="opacity"
            from="0"
            to="1"
            dur=".5s"
            begin="3.4s"
            fill="freeze"
        />
        <animate
            xlinkHref="#8"
            attributeType="CSS"
            attributeName="opacity"
            from="0"
            to="1"
            dur=".5s"
            begin="3.8s"
            fill="freeze"
        />
        <animate
            xlinkHref="#9"
            attributeType="CSS"
            attributeName="opacity"
            from="0"
            to="1"
            dur=".5s"
            begin="4.2s"
            fill="freeze"
        />
        <animate
            xlinkHref="#10"
            attributeType="CSS"
            attributeName="opacity"
            from="0"
            to="1"
            dur=".5s"
            begin="4.6s"
            fill="freeze"
        />
        <animate
            xlinkHref="#11"
            attributeType="CSS"
            attributeName="opacity"
            from="0"
            to="1"
            dur=".5s"
            begin="5s"
            fill="freeze"
        />
        <animate
            xlinkHref="#12"
            attributeType="CSS"
            attributeName="opacity"
            from="0"
            to="1"
            dur=".5s"
            begin="5.4s"
            fill="freeze"
        />
        <animate
            xlinkHref="#13"
            attributeType="CSS"
            attributeName="opacity"
            from="0"
            to="1"
            dur=".5s"
            begin="5.8s"
            fill="freeze"
        />
        <animate
            xlinkHref="#14"
            attributeType="CSS"
            attributeName="opacity"
            from="0"
            to="1"
            dur=".5s"
            begin="6.2s"
            fill="freeze"
        />
    </svg>
)

export default Paws
